<div *ngIf="!isLoading">
  <ng-container *ngIf="!isShowEmptyView">
    <sqv2-order-info></sqv2-order-info>
  </ng-container>
  <history-appt-list *ngIf="hasHistoryScheduleAppointment()" [history]="model.histories" [timezone]="model.addr?.metadata?.timeZoneStandard" (resend)="onBtnResend($event)"></history-appt-list>
  <form *ngIf="!isShowEmptyView" [formGroup]="formInput" nz-form>
    <div class="bg-schedule">
      <div class="step-1">
        <div class="title" style="margin-bottom: 20px; font-size: 16px;">
          <img src="assets/img/shipment-queue/step-1-icon.svg" class="right10"/> Select Contact Method
        </div>
        <nz-radio-group formControlName="contactType" nzButtonStyle="solid">
          <label (click)="onContactTypeChange(type)" *ngFor="let type of appointmentContactTypeArray" nz-radio-button
            [nzValue]="type" [ngStyle]="modelContactType == type && type != contactType ? contactTYpeHighlightStyle : {}">
            {{getAppointmentContactTypeLabel(type)}} {{getHistoriesCountForType(type)}}
          </label>
        </nz-radio-group>
      </div>

      <div *ngIf="contactType">
        <!-- <div class="contact-type-container">
          <div>
            <img src="assets/img/shipment-queue/check-contact-type-icon.svg" /> Contact Method:
            <b>{{getAppointmentContactTypeLabel(contactType)}}</b>
          </div>
          <a (click)="editContactType()">Edit</a>
        </div> -->

        <div class="schedule-appointment">
          <div class="schedule-appointment-content">
            <div *ngIf="shouldShowStep2" class="schedule-appointment-header">
              <div class="title" style="flex-wrap: wrap;"> <img src="assets/img/shipment-queue/step-2-icon.svg" class="right10"/> Schedule Appointment
                <span class="right10 left10">
                  {{appointmentHistoryText}}
                </span>
                <span *ngIf="model?.windows?.length">
                  (Intended Time to Schedule:
                  <span *ngFor="let window of model?.windows; let isLast=last" class="content-italic top20">
                  {{displayWindows(window, model) || 'N/A'}}<span *ngIf="!isLast">, </span>
                  </span>)
                </span>
                <ng-container *ngIf="isShowResendBtn && modelContactType == contactType">
                  <a (click)="onBtnResend(contactType)">{{ contactType == 'phone' ? 'Recall' : 'Resend' }}</a>
                </ng-container>
                
              </div>
              <!-- <a (click)="openViewNote()"><span class="right10" nz-icon nzType="edit" nzTheme="outline"></span>Add & View Notes</a> -->
            </div>

            <div *ngIf="isShowContactInfo" class="bg-content" style="width: 100%;">
              <div class="content" style="margin-bottom: 10px;">Contact to schedule appointment:</div>
              <div *ngFor="let contact of listContact">
                <ng-container [ngTemplateOutlet]="contactInfo" [ngTemplateOutletContext]="{contact, from: contact.from}">
                </ng-container>
              </div>

              <!-- <div *ngFor="let contact of model?.contacts || []">
                <ng-container [ngTemplateOutlet]="contactInfo" [ngTemplateOutletContext]="{contact, from: 'Shipment'}">
                </ng-container>
              </div>

              <div *ngFor="let contact of model?.warehouseContacts">
                <ng-container [ngTemplateOutlet]="contactInfo" [ngTemplateOutletContext]="{contact, from:'Location'}">
                </ng-container>
              </div> -->
            </div>

            <div *ngIf="isShowNoContactFound" class="bg-content" style="width: 80%; background-color: #ffe58f;">
              <div class="content" style="margin-bottom: 10px;">No contact found</div>
            </div>

            <div *ngIf="isShowRequestSentBtn && contactType=='phone'" class="flex-row">
              <button  (click)="confirmRequestSent()" style="margin-top: 15px" nz-button
                nzType="primary">
                <span nz-icon nzType="check" nzTheme="outline"></span>{{requestBtnText}}
              </button>
              <button nz-button *ngIf="isResending" (click)="cancelResend()" style="margin-left: 10px;">Cancel</button>
            </div>

            <ng-container *ngIf="isEmailContact" [ngTemplateOutlet]="emailContactType"></ng-container>
            <ng-container *ngIf="isSmsContact" [ngTemplateOutlet]="smsContactType"></ng-container>

            <div *ngIf="isShowScheduleInput" style="width: 100%;">
              <div class="bottom10 f16 title">
                <img src="assets/img/shipment-queue/step-3-icon.svg" class="right10"/> 
                Please enter the confirmed information in the field below
              </div>
              <div style="margin-bottom: 5px;">{{getLabel('type')}}<span *ngIf="isRequired('type')"
                  class="label-mark-required"></span></div>
              <nz-form-item style="width: 50%;">
                <nz-form-control>
                  <nz-select (ngModelChange)="onChangeAppointmentType($event)" formControlName="type" [nzPlaceHolder]="getPlaceHolder('type')">
                    <nz-option *ngFor="let type of appointmentTypeArray" [nzLabel]="getAppointmentTypeLabel(type)"
                      [nzValue]="type">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <ng-container [ngTemplateOutlet]="tplTimeWindows"
                [ngTemplateOutletContext]="{key: appointmentWindowFormName, form: formInput, type: selectedAppointmentType}">
              </ng-container>
              <button nz-button (click)="onBtnScheduleAppointment()" [disabled]="!isAllowSubmitScheduleAppt"
                nzType="primary" class="top50">
                Schedule Appointment
              </button>
            </div>
          </div>
          <div notes-form class="note" [locationId]="data.stopId" [showLabel]="false"></div>
        </div>
      </div>
    </div>
  </form>

  <div *ngIf="isShowEmptyView" class="bg-schedule">
    <div style="background: white; min-height: 430px; padding: 130px;">
      <nz-empty [nzNotFoundContent]="'Select a Pickup/Drop-off Location'"></nz-empty>
    </div>
  </div>

  <ng-template #emailContactType>
    <div [formGroup]="formInput" *ngIf="isShowSendMail" class="box-with-title" style="margin-top: 15px; width: 80%;" >
      <div class="box-title">Mail to book an appointment</div>
      <div class="box-content">
        <div nz-row [nzGutter]="12">
          <div nz-col nzSpan="24">
            <i>Email (with a link to enter appointment) will be sent to the emails below. <br/>
              Please select the emails you would like to send:
            </i>
          </div>
          <div nz-col nzSpan="24">
            <nz-form-item style="margin-top: 15px">
              <nz-checkbox-group
                formControlName="emails"
                [ngModel]="checkboxOptions"
              ></nz-checkbox-group>
            </nz-form-item>

            <button [disabled]="!isAllowSendMail" (click)="onBtnSendMail()" nz-button
              nzType="primary">
              <span nz-icon nzType="send" nzTheme="outline"></span> Send email
            </button>
            <button nz-button *ngIf="isResending" (click)="cancelResend()" style="margin-left: 10px;">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isShowCannotSendMail" class="box-with-title" style="margin-top: 15px; width: 80%;" >
      <div class="box-title">Mail to book an appointment</div>
      <div class="box-content">
        <div nz-row [nzGutter]="12">
          <div class="bg-content" style="width: 100%; background-color: #ffe58f;">
            <div class="content">
              We could not find any email to contact. Please use other methods or <a (click)="onBtnAddContact()">update the contact</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #smsContactType>
    <div [formGroup]="formInput" *ngIf="isShowSendSms" class="box-with-title" style="margin-top: 15px; width: 80%;" >
      <div class="box-title">Send SMS to book an appointment</div>
      <div class="box-content">
        <div nz-row [nzGutter]="12">
          <div nz-col nzSpan="24">
            <i>SMS (with a link to enter appointment) will be sent to the phone below. <br/>
              Please select phone number you would like to send:
            </i>
          </div>
          <div nz-col nzSpan="24">
            <nz-form-item style="margin-top: 15px">
              <nz-checkbox-group
                formControlName="phoneNumbers"
                [ngModel]="phoneNumberOptions"
              ></nz-checkbox-group>
            </nz-form-item>

            <button [disabled]="!isAllowSendSms" (click)="onBtnSendSMS()" nz-button
              nzType="primary">
              <span nz-icon nzType="send" nzTheme="outline"></span> Send SMS
            </button>
            <button nz-button *ngIf="isResending" (click)="cancelResend()" style="margin-left: 10px;">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isShowCannotSendSms" class="box-with-title" style="margin-top: 15px; width: 80%;" >
      <div class="box-title">Send SMS to book an appointment</div>
      <div class="box-content">
        <div nz-row [nzGutter]="12">
          <div class="bg-content" style="width: 100%; background-color: #ffe58f;">
            <div class="content">We could not find any phone number to send sms. Please use other methods or <a (click)="onBtnAddContact()">update the contact</a></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #contactInfo let-contact='contact' let-from="from">
    <div *ngIf="contact.name || contact.contactName" class="content"><span nz-icon nzType="user" nzTheme="outline"
        style="margin-right: 4px;"></span>Name: {{contact.name || contact.fullName || contact.contactName}}</div>
    <div>
      <span nz-icon nzType="phone" nzTheme="outline" style="margin-right: 4px;"></span>
      {{contact.type == 'primary' ? 'Primary':'Secondary'}} phone: <a *ngIf="contact.phone">{{formatPhone(contact.phone)}}</a> <span *ngIf="!contact.phone">N/A</span> ({{from}})
      <i *ngIf="contact.phone" nz-icon nzType="copy" nzTheme="outline" class="clickable"
        (click)="copyPhoneNumber(formatPhone(contact.phone))" nz-tooltip nzTooltipTitle="Copy phone number"
        style="margin-left: 5px;"></i>
      <span *ngIf="getPhoneType(contact.phone)" class="left5">({{getPhoneType(contact.phone)}})</span>
    </div>
    <div class="content">
      <span style="margin-right: 4px;" nz-icon nzType="mail" nzTheme="outline"></span>
      {{contact.type == 'primary' ? 'Primary':'Secondary'}} email: {{contact.email || 'N/A'}} ({{from}})
      <i *ngIf="contact.email" nz-icon nzType="copy" nzTheme="outline" class="clickable"
        (click)="copyEmail(contact.email)" nz-tooltip nzTooltipTitle="Copy email" style="margin-left: 5px;">
      </i>
    </div>
    <nz-divider></nz-divider>
  </ng-template>

  <ng-template #tplTimeWindows let-key="key" let-form="form" let-type="type">
    <ng-container [formGroup]="form">
      <ng-container [formArrayName]="key">
        <form style="margin-top: 25px;" *ngFor="let item of getControlTimeWindows(); let arrIndex = index"
          nz-form [formGroupName]="arrIndex">
          <div style="display: flex; width: 100%;">
            <div class="right20" style="width: 50%;">
              <div class="form-label-v2 single-line fix-height">
                {{getLabel(fullKey(key, 'date'))}}
                <span *ngIf="isRequired(fullKey(key, 'date'))" class="label-mark-required"></span>
              </div>
              <nz-form-item>
                <nz-form-control>
                  <nz-date-picker (ngModelChange)="isModelChanged=true" [nzAllowClear]="false"
                    formControlName="date" nzFormat="yyyy-MM-dd" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <ng-container *ngIf="type != 'strictAppointment'">
              <div *ngFor="let childKey of ['fromTime', 'toTime']; let i = index" style="flex: 0.5;">
                <div class="form-label-v2 single-line fix-height">
                  {{getLabel(fullKey(key, childKey))}}
                  <span *ngIf="type == 'callAhead'" class="label-mark-required"></span>
                </div>
                <nz-form-item>
                  <nz-form-control>
                    <nz-time-picker [nzMinuteStep]="15" (ngModelChange)="isModelChanged=true"
                      [formControlName]="childKey" nzFormat="HH:mm" [nzPlaceHolder]="getPlaceHolder(fullKey(key, childKey))"
                      [ngClass]="childKey" [nzAllowEmpty]="false" style="width: 100%;">
                    </nz-time-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </ng-container>
            <ng-container *ngIf="type == 'strictAppointment'">
              <div class="flex1">
                <div class="form-label-v2 single-line fix-height">
                  {{getLabel(fullKey(key, 'time'))}}
                  <span *ngIf="isRequired(fullKey(key, 'time'))" class="label-mark-required"></span>
                </div>
                <nz-form-item>
                  <nz-form-control>
                    <nz-time-picker [nzMinuteStep]="15" (ngModelChange)="isModelChanged=true"
                      formControlName="time" nzFormat="HH:mm" [nzPlaceHolder]="getPlaceHolder(fullKey(key, 'time'))"
                      [nzAllowEmpty]="false" style="width: 100%;">
                    </nz-time-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="type == 'callAhead'">
            <div style="margin-bottom: 5px;">{{getLabel(fullKey(key, 'instruction'))}}</div>
            <nz-form-item style="width: 100%;">
              <nz-form-control>
                <textarea
                  nz-input
                  formControlName="instruction"
                  [placeholder]="getPlaceHolder(fullKey(key, 'instruction'))"
                  [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                ></textarea>
              </nz-form-control>
            </nz-form-item>
          </ng-container>
        </form>
      </ng-container>
    </ng-container>
  </ng-template>
</div>


<div *ngIf="isLoading" class="bg-schedule">
  <div style="background: white; min-height: 430px; padding: 130px;">
    <nz-skeleton [nzActive]="isLoading"></nz-skeleton>
  </div>
</div>
