import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Log } from "@services/log";
import { Utils } from "@services/utils";
import { ListFilterDrawer } from "../base/list-filter-drawer";
import { MasterData } from "@services/master.data";
import { TAGS } from "../shipment-entry/components/forms/order-tags/const";
import { ZipcodeSelectorValue } from "../components/common/zipcode-selector";

type WarpIdMode = 'normal' | 'range';

@Component({
  selector: '[orders-filter]',
  templateUrl: './orders-filter.html',
  styleUrls: [
    './orders-filter.scss',
    '../../app.scss',
    '../../drawers/drawer.scss',
    '../../../styles/date-picker-row.scss',
    '../../../styles/form-v2.scss'
  ]
})
export class OrderFilter extends ListFilterDrawer {
  public listTimezones = DateUtil.listUsTimezones;
  pickLocationData = {};
  dropLocationData = {};

  private get declarationForSysadmin(): FormGroupDeclaration {return {
    jobId: {label: 'Job ID', notAcceptEmpty: true},
    isMultiStop: {label: '', notAcceptNull: true},
  }}

  private get declaration(): FormGroupDeclaration {return {
    warpIds: {label: 'WARP IDs', notAcceptEmpty: true},
    clientIds: {label: 'Customer', notAcceptEmpty: true},
    isIncludingSubClient: {label: 'Show results including sub customer', type: 'boolean', initialValue: true},
    carrierId: {label: 'Carrier', notAcceptEmpty: true},
    warehouseId: {label: 'Warehouse', notAcceptEmpty: true},
    sackImportID: {label: 'Sack ID', notAcceptEmpty: true},
    shipmentType: {label: 'Shipment Type', notAcceptEmpty: true},
    isCrossDock: {label: 'Using Cross Dock', notAcceptEmpty: true},
    status: {label: 'Shipment status', notAcceptEmpty: true},
    tags: {label: 'Order Tags', notAcceptEmpty: true},
    routing: {label: 'Routing status', notAcceptEmpty: true},
    requiresAppointment: {label: 'Require Appointment', notAcceptEmpty: true},
    podStatus: {label: 'POD status', notAcceptEmpty: true},
    invoiceStatus: {label: 'Invoice status', notAcceptEmpty: true},
    clientSuccessRepId: {label: 'Client Success Rep', placeHolder: 'Select', notAcceptEmpty: true},
    clientSalesRepId: {label: 'Client Sales Rep', placeHolder: 'Select', notAcceptEmpty: true},
    carrierSalesRepId: {label: 'Carrier Sales Rep', placeHolder: 'Select', notAcceptEmpty: true},
    pickupWarehouseId: {label: 'Pickup Warehouse', placeHolder: 'Select', notAcceptEmpty: true},
    dropoffWarehouseId: {label: 'Dropoff Warehouse', placeHolder: 'Select', notAcceptEmpty: true},
    pickupState: {label: 'From State', placeHolder: 'Select', notAcceptEmpty: true},
    dropoffState: {label: 'To State', placeHolder: 'Select', notAcceptEmpty: true},
    pickupZipcode: {label: 'From Zipcode', placeHolder: 'From Zipcode', notAcceptEmpty: true},
    dropoffZipcode: {label: 'To Zipcode', placeHolder: 'To Zipcode', notAcceptEmpty: true},
    pickupFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    pickupToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    dropoffFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    dropoffToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    actualPickupFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    actualPickupToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    actualDropoffFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    actualDropoffToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    invoiceSentFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    invoiceSentToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    invoiceSentById: {label: 'Invoice sent by', placeHolder: 'Select', notAcceptEmpty: true},
    pickupDateTimezone: {label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
    dropoffDateTimezone: {label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
    actualPickupTimezone: {label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
    actualDropoffTimezone: {label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
    isCostPlus: {label: 'Cost Plus', notAcceptEmpty: true},
    hasMoreThanTwoInvoices: {label: 'More than 2 invoices', notAcceptEmpty: true},
  }}

  protected formGroupDeclaration: FormGroupDeclaration = {};

  private keys = [
    'warpIds',
    'clientIds',
    'carrierId',
    'warehouseId',
    'sackImportID',
    'shipmentType',
    'routing',
    'status',
    'tags',
    'podStatus',
    'invoiceStatus',
    'clientSuccessRepId',
    'clientSalesRepId',
    'carrierSalesRepId',
    // 'pickupState',
    // 'dropoffState',
    // 'pickupZipcode',
    // 'dropoffZipcode',
  ];

  public get formKeys(): string[] {
    if (this.isSysAdmin) {
      return [...Object.keys(this.declarationForSysadmin), ...this.keys];
    } else {
      return this.keys;
    }
  }

  warpIdMode: WarpIdMode = 'normal';
  maxNumWarpId = 200;
  get hintWarpId() {
    if (this.warpIdMode == 'range') {
      return 'Please enter the first & last WARP IDs of the range';
    } else {
      return 'Can be multiple WARP IDs';
    }
  }
  isClearingEvent = false;

  onBtnClear() {
    super.onBtnClear();
    this.pickLocationData = {};
    this.dropLocationData = {};
    this.isClearingEvent = true;
  }

  onWarpIdModeChange(mode: WarpIdMode) {
    if (this.warpIdMode == mode) {
      return;
    }
    this.warpIdMode = mode;
    this.setItemValue('warpIds', null);
    switch (mode) {
      case 'normal':
        this.formGroupDeclaration.warpIds.label = 'WARP ID';
        this.maxNumWarpId = 200;
        break;
      case 'range':
        this.formGroupDeclaration.warpIds.label = 'WARP ID (Range Mode)';
        this.maxNumWarpId = 2;
        break;
    }
  }

  getNameWarpIdMode(mode: WarpIdMode) {
    switch (mode) {
      case 'normal': return 'Normal Mode';
      case 'range': return 'Range Mode';
      default: return mode;
    }
  }

  onZipcodeChange(key, value: ZipcodeSelectorValue) {
    this.setItemValue(key, value?.zipcode)
  }

  public listStatus = Const.OrderStatusArray;
  public listTags = TAGS
  public listShipmentTypes = Const.ShipmentTypesArray;
  public listClients = [];
  public listCarriers = [];
  public listWarehouses = [];
  private fields = [
    'pickupFromDate', 'pickupToDate',
    'dropoffFromDate', 'dropoffToDate',
    'actualPickupFromDate', 'actualPickupToDate',
    'actualDropoffFromDate', 'actualDropoffToDate',
    'invoiceSentFromDate', 'invoiceSentToDate',
  ];
  private timezoneKeys = {
    pickupFromDate: 'pickupDateTimezone',
    pickupToDate: 'pickupDateTimezone',
    dropoffFromDate: 'dropoffDateTimezone',
    dropoffToDate: 'dropoffDateTimezone',
    actualPickupFromDate: 'actualPickupTimezone',
    actualPickupToDate: 'actualPickupTimezone',
    actualDropoffFromDate: 'actualDropoffTimezone',
    actualDropoffToDate: 'actualDropoffTimezone'
  };

  public countriesStates = MasterData.getCountriesStates_forSelectGroup();

  public set metadata(value) {
    if (value && value.client) {
      this.selectedClientIds = value.client;
      if (Utils.isArray(value.client)) {
        this.listClients = value.client;
      } else {
        this.listClients = [value.client];
      }
    }
    if (value && value.carrier) {
      this.selectedCarrierId = value.carrier;
      this.listCarriers = [value.carrier];
    }
    if (value && value.warehouse) {
      this.selectedWarehouseId = value.warehouse.id;
      this.listWarehouses = [value.warehouse];
    }
  }

  get needUpdate(): boolean {
    return true;
  }

  get noDataWarehouse(): string {
    if (!this.selectedClientIds) return 'Please select customer first';
    return 'No data';
  }

  constructor() {
    super();
  }

  public usingCrossDockChecked = false;
  public isIncludingSubClientChecked = false;
  public isCostPlusChecked = false;
  public hasMoreThanTwoInvoicesChecked = false;
  ngOnInit(): void {
    if (this.isSysAdmin) {
      this.formGroupDeclaration = {...this.declarationForSysadmin, ...this.declaration};
    } else {
      this.formGroupDeclaration = this.declaration;
    }
    super.ngOnInit();
    this.needAppointmentChecked = this.getItemValue('requiresAppointment');
    this.usingCrossDockChecked = this.getItemValue('isCrossDock');
    this.isIncludingSubClientChecked = this.getItemValue('isIncludingSubClient');
    this.isCostPlusChecked = this.getItemValue('isCostPlus');
    this.hasMoreThanTwoInvoicesChecked = this.getItemValue('hasMoreThanTwoInvoices');
    this.getListWarehouse();
    this.fetchListClientSuccessRep();
    this.fetchListClientSalesRep();
    this.fetchListCarrierSalesRep();
    this.fetchListAccounting();
    this.setEnableFormGroup(true);
  }

  getApiListDataForFilter(key: string): string|undefined {
    switch (key) {
      case 'clientIds': return `${Const.APIURI_CLIENTS_FOR_FILTER}?includeSubAccount=true`;
      case 'carrierId': return Const.APIURI_CARRIERS_FOR_FILTER;
      default: return
    }
  }

  onCheckboxUsingCrossDock(value) {
    this.usingCrossDockChecked = !!value;
    this.setItemValue('isCrossDock', this.usingCrossDockChecked || null);
  }

  onCheckboxIncludingSubClient(value) {
    this.isIncludingSubClientChecked = !!value;
    this.setItemValue('isIncludingSubClient', this.isIncludingSubClientChecked || false);
  }

  onCheckboxCostPlus(value) {
    this.isCostPlusChecked = !!value;
    this.setItemValue('isCostPlus', this.isCostPlusChecked || null);
  }

  onCheckboxMoreThanTwoInvoices(value) {
    this.hasMoreThanTwoInvoicesChecked = !!value;
    this.setItemValue('hasMoreThanTwoInvoices', this.hasMoreThanTwoInvoicesChecked || null);
  }

  protected beforeBindModel(model: any): void {
    if (model.client) {
      this.listClients = [model.client];
    }
    this.pickLocationData = {
      state: model.pickupState,
      zipcode: model.pickupZipcode,
      warehouseId: model.pickupWarehouseId
    }
    this.dropLocationData = {
      state: model.dropoffState,
      zipcode: model.dropoffZipcode,
      warehouseId: model.dropoffWarehouseId
    }

    for(let field of this.fields) {
      if(model[field]) {
        const timezoneKey = this.timezoneKeys[field];
        if (timezoneKey && model[timezoneKey]) {
          let timezone = DateUtil.mapTimezoneUS(model[timezoneKey]);
          const date = DateUtil.convertLocalTime2(model[field], timezone).toISOString();
          model[field] = new Date(date);
        } else {
          model[field] = new Date(model[field]);
        }
      }
    }
    return model;
  }

  protected afterBindModel(model?: any): void {
    this.updateNeedRouteCheck();
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(isCreateNew);
    for(let field of this.fields) {
      if (json[field]) {
        const timezoneKey = this.timezoneKeys[field];
        if (timezoneKey && json[timezoneKey]) {
          let timezone = DateUtil.mapTimezoneUS(json[timezoneKey]);
          let date = DateUtil.toBeginOfDay(json[field]).toISOString();
          if(field.includes("ToDate")) date = DateUtil.toEndOfDay(json[field]).toISOString();
          date = DateUtil.convertLocalTime(new Date(date), timezone).toISOString();
          json[field] = date;
        } else {
          let date = DateUtil.toBeginOfDay(json[field]).toISOString();
          if(field.includes("ToDate")) date = DateUtil.toEndOfDay(json[field]).toISOString();
          json[field] = date;
        }
      }
    }
    if (!json.clientIds?.length) {
      delete json.isIncludingSubClient;
    }
    return json;
  }

  protected getMetadata() {
    let client = this.listClients.filter(it => this.selectedClientIds?.includes(it._id));
    let warehouse = this.listWarehouses.filter(it => it.id == this.selectedWarehouseId)[0];
    let carrier = this.listCarriers.filter(it => it._id == this.selectedCarrierId)[0];
    return {client, warehouse, carrier}
  }

  private selectedClientIds;
  private selectedWarehouseId;
  private selectedCarrierId;

  onClientChange(clientIds) {
    this.selectedClientIds = clientIds;
  }

  onCarrierChange(carrierId) {
    this.selectedCarrierId = carrierId;
  }

  showWarehouseName(item) {
    let arr = [];
    if (item?.warpId) arr.push(item.warpId);
    if (item?.name) arr.push(item.name);
    return arr.join(" - ")
  }

  private getListWarehouse() {
    let url = `${Const.APIURI_WAREHOUSES}/list/all_for_filter`
    this.api.GET(url).subscribe(
      resp => {
        this.listWarehouses = resp?.data?.list_data ?? [];
      }, err => {
        Log.e(err);
      }
    )
  }

  private getListClients() {
    let url = `${Const.APIURI_CLIENTS}/list/all_for_filter`
    this.api.GET(url).subscribe(
      resp => {
        this.listClients = resp?.data?.list_data ?? [];
      }, err => {
        Log.e(err);
      }
    )
  }

  onWarehouseChange(itemId) {
    this.selectedWarehouseId = itemId;
  }

  public needRouteChecked = false;

  onCheckboxNeedRoute(value) {
    if (value) {
      this.setItemValue('routing', 'not_routed');
      this.setItemValue('status', [Const.OrderStatus.needCarrier]);
    } else {
      this.setItemValue('routing', null);
      this.setItemValue('status', null);
    }
  }

  public needAppointmentChecked = false;

  onCheckboxNeedAppointment(value) {
    if (value) {
      this.setItemValue('requiresAppointment', true);
      this.needAppointmentChecked = true;
    } else {
      this.setItemValue('requiresAppointment', null);
      this.needAppointmentChecked = false;
    }
  }

  private updateNeedRouteCheck() {
    let routing = this.getItemValue('routing');
    let statuses = this.getItemValue('status');
    this.needRouteChecked = routing == 'not_routed' && Utils.isArrayNotEmpty(statuses) && statuses.includes(Const.OrderStatus.needCarrier);
  }

  onInputItemChanged(key: string, value: any) {
    switch (key) {
      case 'routing':
      case 'status':
        this.updateNeedRouteCheck();
        break;
      case 'pickupDateTimezone':
        break;
      case 'dropoffDateTimezone':
        break;
      case 'actualPickupTimezone':
        break;
      case 'actualDropoffTimezone':
        break;
      default:
        break;
    }
  }

  onLocationChangeValue(key: string, input: any) {
    const value = input.value;
    this.isClearingEvent = false;
    switch  (key) {
      case 'pickLocation':
        if (value?.locationType == "customize") {
          this.setItemValue('pickupWarehouseId', null);
          this.setItemValue('pickupState', value?.state);
          this.setItemValue('pickupZipcode', value?.zipcode);
        } else {
          this.setItemValue('pickupWarehouseId', value?.warehouseId);
          this.setItemValue('pickupState', null);
          this.setItemValue('pickupZipcode', null);
        }
        break
      case 'dropLocation':
        if (value?.locationType == "customize") {
          this.setItemValue('dropoffWarehouseId', null);
          this.setItemValue('dropoffState', value?.state);
          this.setItemValue('dropoffZipcode', value?.zipcode);
        } else {
          this.setItemValue('dropoffWarehouseId', value?.warehouseId);
          this.setItemValue('dropoffState', null);
          this.setItemValue('dropoffZipcode', null);
        }
        break;
    }
    // this.setItemValue(key, value);
  }

  public listClientSuccessRep = [];
  public isFetchingClientSuccessRep = false;
  private fetchListClientSuccessRep() {
    this.isFetchingClientSuccessRep = true;
    this.api.getListClientSuccessRepUsersFilter().subscribe(
      resp => {
        this.listClientSuccessRep = resp?.data?.list_data ?? [];
        this.isFetchingClientSuccessRep = false;
      }, err => {
        this.showErr(err);
        this.isFetchingClientSuccessRep = false;
      }
    );
  }

  public listClientSalesRep = [];
  public isFetchingClientSalesRep = false;
  private fetchListClientSalesRep() {
    this.isFetchingClientSalesRep = true;
    this.api.getListClientSalesRepUsersFilter().subscribe(
      resp => {
        this.listClientSalesRep = resp?.data?.list_data ?? [];
        this.isFetchingClientSalesRep = false;
      }, err => {
        this.showErr(err);
        this.isFetchingClientSalesRep = false;
      }
    );
  }

  public listCarrierSalesRep = [];
  public isFetchingCarrierSalesRep = false;
  private fetchListCarrierSalesRep() {
    this.isFetchingCarrierSalesRep = true;
    this.api.getListAdminUsers().subscribe(
      resp => {
        this.listCarrierSalesRep = resp?.data?.list_data ?? [];
        this.isFetchingCarrierSalesRep = false;
      }, err => {
        this.showErr(err);
        this.isFetchingCarrierSalesRep = false;
      }
    );
  }

  public listAccouting = [];
  public isFetchingAccounting = false;
  private fetchListAccounting() {
    this.isFetchingAccounting = true;
    this.api.getListAccountingUsersFilter().subscribe(
      resp => {
        this.listAccouting = resp?.data?.list_data ?? [];
        this.isFetchingAccounting = false;
      }, err => {
        this.showErr(err);
        this.isFetchingAccounting = false;
      }
    );
  }

  private pickupState;
  private dropoffState;
  onPickUpStateChanged(state) {
    this.pickupState = state;
  }
  onDeliveryStateChanged(state) {
    this.dropoffState = state;
  }

  public isSearchZipcode = {
    pickupZipcode: false,
    dropoffZipcode: false
  }
  onSearchZipcode(key: string, isSearching: boolean) {
    this.isSearchZipcode[key] = isSearching;
  }
  get isSearchingZipcode() {
    return this.isSearchZipcode.pickupZipcode || this.isSearchZipcode.dropoffZipcode;
  }

}
