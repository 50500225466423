import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: '[short-item-info]',
    templateUrl: './index.html',
    styleUrls: ['../styles.scss']
})
export class ShortItemInfo extends Component implements OnChanges {
    @Input() item: any = null
    totalWeight = null

    ngOnChanges(changes: SimpleChanges): void {
        this.totalWeight = this.item?.totalWeight || (this.item.qty && this.item.weightPerUnit ? this.item.qty * this.item.weightPerUnit : null)
    }
}