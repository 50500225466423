
import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";

interface IHistoryItem {
  content: string,
  time: string,
  by: string,
  type: string
}

@Component({
  selector: "history-appt-list",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class HistoryDialog extends BaseComponent {

  private _history: [];
  private _timezone: any;
  items: any;

  @Input() get timezone() {
    return this._timezone;
  };
  set timezone(value) {
    this._timezone = value;
  }

  @Input() get history() {
    return this._history
  };
  set history(value) {
    this._history = value;
    if (value) {
      this.items = this.formatData(value);
    }
  }
  @Output() resend: EventEmitter<any> = new EventEmitter();

  public isShowingFull = false;
  public get listData() {
    if(this.items?.length <= 2) return this.items;
    else {
      if(this.isShowingFull) return this.items;
      return this.items.slice(0,2);
    }

  }
  @ViewChild('show_more') showMore: TemplateRef<any>;
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  formatData(data) {
    let items : IHistoryItem[] = [];
    for (let item of data) {
      let date = item.insert?.when;
      let time = DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME)
      let {content, contactType} = this.getContent(item);
      let by = this.getFullName(item?.metadata?.user);
      items.push({content, time, by, type: contactType});
    }
    return items.reverse();
  }

  getContent(item) {
    let json = JSON.parse(item?.metadata?.changeInfo || "{}");
    let contactType = json?.appointmentInfo?.contactType;
    let status = json?.appointmentInfo?.status;
    if(contactType === Const.AppointmentContactType.email) contactType = "Email";
    if(contactType === Const.AppointmentContactType.phone) contactType = "Called";
    if(contactType === Const.AppointmentContactType.sms) contactType = "SMS";
    if (contactType && item?.message && status == Const.AppointmentStatus.confirmed) {
      return { contactType, content: item?.message }
    }
    if(contactType) return {content: contactType + ' ' + 'appointment', contactType};
    if (!contactType && item?.message) {
      return { contactType, content: item?.message }
    }
    return { contactType, content: '' }
  }

  getWhenBy(item: IHistoryItem) {
    let rs = '';
    if (item.time) {
      rs += item.time;
    }
    if(item.by) {
      rs += ' by ' + item.by;
    }
    return rs;
  }

  onBtnResend(item: IHistoryItem) {
    switch (item.type) {
      case 'Email':
        this.resend.emit(Const.AppointmentContactType.email);
        break;
      case 'SMS':
        this.resend.emit(Const.AppointmentContactType.sms);
        break;
      case 'Called':
        this.resend.emit(Const.AppointmentContactType.phone);
        break;
    }
  }

  shouldShowPending() {
    return this.items.length > 2 && !this.isShowingFull;
  }
}
