import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { LinehaulLaneV2ListFilter } from '.';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ModuleFilterLayout } from '@app/admin/components/filter-layout/module';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
    NzSelectModule,
    ModuleFilterLayout,
  ],
  declarations: [
    LinehaulLaneV2ListFilter,
  ],
  exports: [
    LinehaulLaneV2ListFilter
  ],
  providers: [
  ]
})
export class ModuleLinehaulLaneV2ListFilter { }