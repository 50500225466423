import { Component, Input, EventEmitter, Output } from "@angular/core";
import { DedicatedLaneData } from "@app/admin/dedicated-lanes/dedicated-lane.interface";
import { InputHelper } from "@services/input-helper";
import { FormDataShipmentEntryDraftData } from "@wearewarp/types-server-admin/form-data/shipment-entry";
@Component({
  selector: "[shipment-entry-info-summary]",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class ShipmentEntryInfoSummary {
  @Input() modelDraft: FormDataShipmentEntryDraftData;
  @Input() dedicatedLaneData: DedicatedLaneData;
  @Input("isSingle") isSingle: boolean;
  @Output() onSortedTaskIdsChanged = new EventEmitter();

  updateSortedTaskIds(data) {
    this.onSortedTaskIdsChanged.emit(data);
  }
  formatMoney(value: number | string) {
    return InputHelper.formatMoney2(`${value || 0}`);
  }
}
