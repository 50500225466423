import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Emitter } from "@ckeditor/ckeditor5-utils";
import { BizUtil } from "@services/biz";
import { ShipmentItem } from "@wearewarp/types/data-model";

interface ShipmentItemExtend extends ShipmentItem {
    checked: boolean;
}

@Component({
    selector: '[table-shipment-items]',
    templateUrl: './index.html',
    styleUrls: ['../styles.scss']
})
export class TableShipmentItems extends Component {
    @Input() items: ShipmentItemExtend[] = null;
    @Output() setItems: EventEmitter<any> = new EventEmitter<any>();
    checked: boolean = true;
    indeterminate: boolean = false;
    setOfCheckedId = new Set<string>();

    ngOnInit(): void {
        this.setOfCheckedId = new Set<string>(this.items.map(item => item.id));
        const items = this.items.map(item => {
            if (this.setOfCheckedId.has(item.id)) item.checked = true;
            else item.checked = false;
            return item;
        });
        this.setItems.emit(items);
    }

    onAllChecked(checked: boolean): void {
        this.items.forEach(item => this.updateCheckedSet(item.id, checked));
    }

    refreshCheckedStatus(): void {
        this.checked = this.items.every(({ id }) => this.setOfCheckedId.has(id));
        this.indeterminate = this.items.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
    }

    onItemChecked(id: string, checked: boolean): void {
        this.updateCheckedSet(id, checked);
        this.refreshCheckedStatus();
    }

    updateCheckedSet(id: string, checked: boolean): void {
        if (checked) {
          this.setOfCheckedId.add(id);
        } else {
          this.setOfCheckedId.delete(id);
        }
        const items = this.items.map(item => {
            if (this.setOfCheckedId.has(item.id)) item.checked = true;
            else item.checked = false;
            return item;
        });
        this.setItems.emit(items);
      }

    getBarcodeTxt(item: ShipmentItem): string {
        return item.barcodes?.join(', ') || '';
    }

    getDimensionTxt(item: ShipmentItem): string {
        return `${item.length} x ${item.width} x ${item.height} ${item.sizeUnit?.toLowerCase()}`;
    }

    getWeightTxt(item: ShipmentItem): string {
        const totalWeight = item?.totalWeight || (item.qty && item.weightPerUnit ? item.qty * item.weightPerUnit : null);
        return `${totalWeight} ${item.weightUnit?.toLowerCase()}`;
    }

    getServicetTxt(item: ShipmentItem): string {
        let str = BizUtil.getItemServiceDesc(item);
        return str || 'N/A'
    }
}