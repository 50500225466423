<div nz-form [formGroup]="formInput">
  <ng-container *ngFor="let key of keys">
    <div *ngIf="getLabel(key)" class="field-label">
      {{getLabel(key)}}
      <span *ngIf="isRequired(key)" class="label-mark-required"></span>
    </div>
    <nz-form-item>
      <nz-form-control>
        <ng-container [ngSwitch]="key">

          <div *ngSwitchCase="'vehicleTypes'"
            vehicle-selector
            [mode]="'multiple'"
            [placeholder]="getPlaceHolder(key)"
            [isDisabled]="isReadOnly(key)"
            [value]="getVehicleType()"
            (valueChange)="onValueChange(key, $event)">
          </div>

          <div *ngSwitchCase="'warehouseIds'" [formArrayName]="key">
            <div *ngFor="let f of getArrayControls(key); let i = index">
              <div class="field-label">
                Stop {{i + 1}}
                <span class="label-mark-required"></span>
              </div>
              <nz-form-item>
                <nz-form-control>
                  <div style="display: flex; align-items: center;">
                    <div select-warehouse style="flex: 1;" [formControlName]="i" [canSelectItem]="canSelectWarehouse"></div>
                    <button nz-button nzDanger nzType="text" [disabled]="!canRemoveStop(i)"
                      (click)="onBtnRemoveStop(i)" 
                      nz-tooltip nzTooltipTitle="Remove stop {{i+1}}">
                      <i nz-icon nzType="close" nzTheme="outline"></i>
                    </button>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            <a style="float: right;" (click)="onBtnAddMoreStop()">Add more stop</a>
          </div>

          <div select-client *ngSwitchCase="'clientIds'" [formControlName]="key" [multiple]="true"></div>

          <ng-container *ngSwitchCase="'group'" >
            <nz-select
              [formControlName]="key"
              [nzDropdownRender]="tplAddGroup"
              nzDropdownClassName="linehaul-lane-v2-create-select-group"
              nzShowSearch nzAllowClear>
              <nz-option *ngFor="let item of groups" [nzValue]="item" [nzLabel]="item"></nz-option>
            </nz-select>
            <ng-template #tplAddGroup>
              <nz-divider></nz-divider>
              <div style="display: flex; align-items: center; padding: 12px;">
                <input type="text" nz-input #inputElement style="flex: 1;"/>
                <a (click)="addGroup(inputElement)" style="width: 120px; padding-left: 15px;">
                  <i nz-icon nzType="plus"></i>
                  New group
                </a>
              </div>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <input nz-input *ngIf="!isMultiline(key)"
              [formControlName]="key"
              [type]="getInputType(key)"
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)">
            <textarea nz-input *ngIf="isMultiline(key)"
              [formControlName]="key"
              [placeholder]="getPlaceHolder(key)"
              [nzAutosize]="{ minRows: 2, maxRows: 3 }"
              (input)="onInputChanged($event, key)"></textarea>
          </ng-container>

        </ng-container>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
</div>