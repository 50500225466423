import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableShipmentItems } from './';
import { NzTableModule } from 'ng-zorro-antd/table';

@NgModule({
  imports: [
    CommonModule,
    NzTableModule
  ],
  declarations: [TableShipmentItems],
  exports: [TableShipmentItems]
})
export class ModuleTableShipmentItems {}