import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Observable } from "rxjs";
@Component({
  selector: 'edit-shipment-entry-contact-info',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class EditShipmentEntryInstructions extends BaseFormDialog1 {

  @Input() onSave: (data) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;
  @Input() headerText = 'Edit Instructions';
  oldFiles = [];

  protected formGroupDeclaration: FormGroupDeclaration = {
    instructions: {label: ''},
  }
  
  ngOnInit(): void {
    this.oldFiles = this.model?.instructionImgs?.files ?? [];
    super.ngOnInit();
  }

  get isCreateNew(): boolean {
    return !this.model;
  }
  get needUpdate(): boolean {
    let fileKeys = Object.keys(this.fileToUpload);
    if (fileKeys?.length || this.isDeletedImage) {
      return true;
    }
    return super.needUpdate;
  }

  fileToUpload: { [key: string]: any } = {};
  protected getFormData(isCreateNew: boolean): FormData {
    let jsonData = this.getFormData_JSON(isCreateNew);
    jsonData.instructionImgs = {
      files: this.oldFiles,
    };
    for (const key of ['shipmentIds', 'deliveryIds', 'locationType', 'taskIds']) {
      if (this.model[key]) {
        jsonData[key] = this.model[key];
      }
    }
    let fileKeys = Object.keys(this.fileToUpload);
    let formData = new FormData();
    for (let key of fileKeys) {
      const count = this.fileToUpload[key]?.length;
      if (count > 0) {
        for (let index = 0; index < count; index++) {
          const file = this.fileToUpload[key][index];
          formData.append(
            `${key}.${index}`,
            file.file,
            file.fileName,
          );
        }
      } else if (this.model && this.model[key] && this.model[key].deleted) {
        jsonData[key] = null;
      }
    }
    formData.append("params", JSON.stringify(jsonData));
    return formData;
  }

  onBtnSave() {
    if (!this.needUpdate) return;
    let data = this.getFormData(true);
    console.log(data);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailOrder();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

  onFileImageSelectedChange(key, files) {
    this.fileToUpload[key] = files;
  }

  isDeletedImage = false;
  onFileUploadedImageChange(key, files) {
    this.oldFiles = files;
    this.isDeletedImage = true;
  }
    
}
