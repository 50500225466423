<style type="text/css">
  .model-btns {
    display: flex; flex-direction: row; align-items: center; justify-content: center;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
  }
  .dlg-success {
    width: 437px;
  }
  .dlg-items {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
    
<div>
  <div class="top20 dlg-items">
    <img class="bottom20" nz-image width="83px" height="83px" src="/assets/img/ic_success.png" />
  </div>

  <div class="title dlg-items bottom20">Merged Order Successfully</div>

  <div *ngFor="let order of orders">
    <div class="bottom10" style="text-align: center;"> 
      Order WARP ID: 
      <a [routerLink]="[routeAdminOrderList, order.id]" (click)="closeDialog()">{{showOrderCode(order)}}</a>
    </div>
    <div class="dlg-items bottom10"><a [href]="getTrackingLink(order)" target="_blank">{{getTrackingLink(order)}}</a>
      <span nz-icon nzType="copy" nzTheme="outline" (click)="copyTrackingLink(order)" nz-tooltip
      nzTooltipTitle="Copy Tracking URL" style="cursor: pointer; margin-left: 8px;"></span>
    </div>
  </div>


  <div class="top20 bottom20 model-btns">
    <button nz-button nzType="primary" style="width: 70px;" (click)="onDone()">Done</button>
  </div>
</div>
    