import { Component, ElementRef, ViewChild, OnChanges, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Const } from '@app/const/Const';
import _ from "underscore";
import { BaseComponent } from '@abstract/BaseComponent';
import { Shipment as ShipmentModel, DeliveryInfo } from "@wearewarp/types/data-model";
import RoutingService from './routing';
import { DeliveryMap } from '../components/map';
import { Route } from './route';
import { RouteOrder } from './route_order';

@Component({
    selector: '[delivery-planning]',
    templateUrl: './index.html',
    styleUrls: ['./styles.scss']
})
export class DeliveryPlanning extends BaseComponent {
    _shipments: ShipmentModel[] = []
    @Input() shipmentIds: string[] = []
    @Input() mode: string = null
    @Input() canMerge: boolean = false
    initializing: boolean = false

    @Input() set shipments(value) {
        this._shipments = value;
    }
    get shipments() {
        return this._shipments
    }

    @Output() onRouteCreated: EventEmitter<any> = new EventEmitter<any>();

    public routing: RoutingService = new RoutingService()
    public route: Route = null
    public selectedShipment: any = null
    map: DeliveryMap
    
    @ViewChild('mapboxContainer', { static: true }) mapboxContainer: ElementRef;
    @ViewChild('deckCanvas', { static: true }) deckCanvas: ElementRef;
    @ViewChild('routeOrder') routeOrder: RouteOrder;

    ngOnInit(): void {
        this.onPickLocation = this.onPickLocation.bind(this)
        this.map = new DeliveryMap('', this.mapboxContainer, this.deckCanvas)
        this.map.onPickLocation = this.onPickLocation

        this.loadData()
        this.loadWarehouses()
    }

    async loadShipments() {
        const res = await this.api.POST(Const.APIV2(`shipments/load`), {ids: this.shipmentIds}).toPromise()
        this._shipments = res.data.list_data
    }

    getLngLat(addr) {
        const { metadata } = addr || {}
        const { latitude, longitude } = metadata || {}
        if (!latitude || !longitude) return null
        return {lng: longitude, lat: latitude}
    }

    async loadData() {
        this.initializing = true

        if (this.mode == 'id' || (!this.shipments && this.shipmentIds)) {
            await this.loadShipments()
        }

        this.map.loadShipments(this.shipments || [])

        // extract list of stops
        for (let s of this.shipments) {
            for (let stop of s.deliveryInfos) {
                stop.warpId = s.warpId
                stop.code = s.code;
            }
        }

        const stops = _.flatten(this.shipments.map(s => (s.deliveryInfos || []).filter(it => it.type === 'PICKUP' || it.type === 'DROPOFF').map(it => Object.assign({shipment_id: s.id}, it))))
        
        await this.routing.init(stops)

        this.route = this.routing.singleRoute()

        this.map.loadRoutes([this.route])
        this.map.refresh()
        // fit bounds
        const bounds = stops.map(it => it.addr).map(this.getLngLat).filter(it => it)
        this.map.fitBounds(bounds)
    }

    warehouses: any[] = []
    loadWarehouses() {
        const url = `${Const.APIURI_WAREHOUSES}?limit=-1&filter=${JSON.stringify({warehouseType: 'crossdock'})}`
        this.api.GET(url).subscribe((res) => {
            this.warehouses = res.data.list_data
            this.map.loadWarehouses(this.warehouses)
            this.map.refresh()
            // this.calibrateMap()
        })
    }

    optimize() {
        this.route = this.routing.optimizeRoute(this.route)
        this.map.loadRoutes([this.route])
        this.map.refresh()
    }

    changeOrder(orders) {
        const startLocation = this.route.startLocation
        const startTime = this.route.startTime
        this.route =  this.routing.manualRoute(orders)
        this.route.startLocation = startLocation
        this.route.startTime = startTime
        this.map.loadRoutes([this.route])
        this.map.refresh()
    }

    async onPickLocation(location) {
        if (this.selectingDriverLocation) {
            this.route.startLocation = location

            await this.routing.refreshLocations([location])

            this.route = this.routing.optimizeRoute(this.route)

            this.selectingDriverLocation = false
            this.map.loadTruckLocations([location])
            this.map.loadRoutes([this.route])
            this.map.refresh()
        }
    }

    selectingDriverLocation: boolean = false
    onSelectDriverLocation() {
        this.selectingDriverLocation = true
    }

    onMouseOverStop(event) {
        const { location } = event || {}
        if (location) {
            this.map.loadHighlightLocations([location])
        } else  {
            this.map.loadHighlightLocations([])
        }
        this.map.refresh()
    }

    public additionalShipmentIds
    public loadingShipment: boolean = false
    onInputKeyPress(e) {
        if (!this.additionalShipmentIds) return
        if (e.code === 'Enter') {
            const existings = this._shipments.map(it => it.id).concat(
                this._shipments.map(it => it.warpId.toString())
            )

            const ids = this.additionalShipmentIds.split(/[^A-Za-z0-9-]/).map(it => it.trim())
                .filter(it => it)
                .filter(it => existings.indexOf(it) < 0)            
            this.additionalShipmentIds = ''

            if (ids && ids.length) {
                this.loadingShipment = true
                this.api.POST(Const.APIV2(`shipments/load`), {ids}).subscribe((res) => {
                    this.loadingShipment = false
                    const newShipments = res.data.list_data
                    if (!newShipments || !newShipments.length) return
                    for (let s of newShipments) {
                        for (let stop of s.deliveryInfos) {
                            stop.warpId = s.warpId
                            stop.code = s.code;
                        }
                    }

                    if (!this.canMerge) {
                        for (let s of newShipments) {
                            if (s.lastJobId) {
                                this.showErr(`Shipment ${s.warpId} has been added to route!!!`)
                                return
                            }
                        }                        
                    }
            
                    this._shipments = (this._shipments || []).concat(newShipments)
                    this.map.loadShipments(this._shipments || [])

                    const stops = _.flatten(this._shipments.map(s => (s.deliveryInfos || []).filter(it => it.type === 'PICKUP' || it.type === 'DROPOFF').map(it => Object.assign({shipment_id: s.id}, it))))
                    for (let stop of stops) {
                        stop.id = stop.id || `${stop.shipment_id}-${stop.type}`
                    }
        
                    this.routing.init(stops).then((r) => {
                        const start = this.route?.startLocation
                        this.route = this.routing.singleRoute(start)
            
                        this.map.loadRoutes([this.route])
                        const bounds = stops.map(it => it.addr).map(this.getLngLat).filter(it => it)
                        this.map.fitBounds(bounds)
                
                        this.map.refresh()
                    })            
                }, 
                (err) => {
                    this.loadingShipment = false;
                    this.showErr(err);
                })
            }
        }
    }
    clear() {
        this._shipments = []
        this.route = null
        this.map.loadShipments(this._shipments)
        this.map.loadTruckLocations([])
        this.map.loadRoutes([])
        this.map.refresh()

        const stops = _.flatten(this._shipments.map(s => (s.deliveryInfos || []).filter(it => it.type === 'PICKUP' || it.type === 'DROPOFF')))

        this.routing.refreshLocations([])
        this.routing.init(stops)
    }

    exporting: boolean = false
    exportedJob: any = null
    confirmingExport: boolean = false
    confirmingMerge: boolean = true
    onConfirmingExport(confirming) {
        let err = this.routeOrder?.validateError();
        if (err) {
          const msg = `${err}<br>Please correct all before exporting the route.`;
          return this.showDialog(msg);
        }
        this.confirmingExport = confirming
    }
    export() {
        if (!this.route) return
        this.exporting = true
        this.confirmingExport = false
        const payload = Object.assign({}, this.route, { merge: this.canMerge && this.confirmingMerge })
        return this.api.POST(Const.APIV2(`planning/create-route`), payload).subscribe((res) => {
            console.log('CREATED', res)
            this.exporting = false
            if (res.id) {
                this.onRouteCreated.emit(res)
                this.exportedJob = res
            }
        }, (err) => {
            this.exporting = false
            this.showErr(err?.message ?? `Error while creating route!!!`)
        })
    }

    confirmExportedJob() {
        this.clear()
        this.exportedJob = null
    }

    get canCreateExternalRoute() {
        return this._shipments.length === 1; 
    }
    onBtnCreateExternalRoute() {
        if (!this.canCreateExternalRoute) return;
        this.confirmYesNo('Do you really want to create external route?', () => {
            this.exporting = true
            this.confirmingExport = false
            const payload = { shipmentId: this._shipments?.[0]?.id }
            return this.api.POST(Const.APIV2(`planning/create-external-route`), payload).subscribe((res) => {
                this.exporting = false
                if (res.id) {
                    this.onRouteCreated.emit(res)
                    this.exportedJob = res
                }
            }, (err) => {
                this.exporting = false
                this.showErr(err?.message ?? `Error while creating external route!!!`)
            })
        });
    }
}