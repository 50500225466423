<form nz-form [formGroup]="formInput">
  <div class="bottom20">
    <form nz-form [formGroupName]="'settings'">
      <div *ngIf="'isCostPlus' as subKey" class="bottom10">
        <div nz-checkbox [formControlName]="subKey">
          {{getLabel(join('settings',subKey))}}
        </div>
      </div>
      <do-not-invoice
        [doNotInvoiceData]="{
          isDoNotInvoice: getItemValue('settings.isDoNotInvoice'),
          reason: getItemValue('settings.reasonDoNotInvoice')
        }"
        (doNotInvoiceChange)="onDoNotInvoiceChange($event)">
      </do-not-invoice>
    </form>
  </div>
  <div form-input-cost-v3 #costForm [form]="formInput.get('cost')"
    [createShipmentMode]="'single'"
    [selectLocations]="selectLocations"
    [serviceOptions]="serviceOptionsForCostSection">
  </div>
</form>
