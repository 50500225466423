import {
  FormDataShipmentLocation,
  FormDataShipmentItem,
  FormDataBatchLocationsItem,
  FormDataShipmentEntryDraftData,
  FormDataShipmentEntryBatchShipmentItem
} from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { BaseForm } from "../base/form-base";
import { TabContentContext } from "../base/tabs/interface";
import { EventEmitter } from "@angular/core";
import { ShipmentEntryMode } from "@wearewarp/types";
import { DedicatedLaneData } from "../dedicated-lanes/dedicated-lane.interface";

export interface ComponentForm<T> {
  getForm(): BaseForm<T>;
}

export function isComponentForm(obj: any):  obj is ComponentForm<any> {
  return typeof (obj as ComponentForm<any>)?.getForm === 'function';
}

export type ShipmentEntryContextProgressCallback = (status: 'inProgress' | 'succeeded' | 'failed', dataOrError?: any) => void;

// LocationItem dùng cho component drag and drop
export type LocationItem = {
  serviceOptions?: Array<string>,
  shipmentId?: any,
  shipmentWarpId?: any,
  type?: string,
  items?: Array<any>,
}

export interface ShipmentEntryContext extends TabContentContext {
  modelDraft?: FormDataShipmentEntryDraftData,
  dedicatedLane?: DedicatedLaneData,
  readonly modelDraftChange: EventEmitter<FormDataShipmentEntryDraftData>,
  saveDeliveryInfo: (data: FormDataShipmentLocation, onProgress: ShipmentEntryContextProgressCallback) => void,
  saveDeliveryInfos: (data: FormDataBatchLocationsItem, index: number, onProgress: ShipmentEntryContextProgressCallback) => void,
  saveShipments: (data: FormDataShipmentEntryBatchShipmentItem, index: number, onProgress: ShipmentEntryContextProgressCallback) => void,
}

export interface FormDataOrderItems {
  items: Array<FormDataShipmentItem>,
  deleteIds?: Array<string>,
}

export interface FormDataBatchLocations {
  batchType: ShipmentEntryMode,
  data: FormDataBatchLocationsMultiPick | FormDataBatchLocationsMultiDrop | FormDataBatchLocationsMultiPickDrop
}



export interface FormDataBatchLocationsMultiPick {
  pickInfos?: Array<FormDataBatchLocationsItem>,
  dropInfo?: FormDataShipmentLocation,
}

export interface FormDataBatchLocationsMultiDrop {
  pickInfo?: FormDataShipmentLocation,
  dropInfos?: Array<FormDataBatchLocationsItem>,
}

export interface FormDataBatchLocationsShipment {
  id?: string,
  pickInfo: FormDataShipmentLocation,
  dropInfo: FormDataShipmentLocation,
  items: Array<FormDataShipmentItem>
}

export interface FormDataBatchLocationsMultiPickDrop {
  shipments?: Array<FormDataBatchLocationsShipment>
}

