import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { DateUtil } from "@services/date-utils";
import { PlanningService } from "@services/planning.service";
import { Utils } from "@services/utils";
import { ResponseLinehaulLaneListItem } from "@wearewarp/types-server-admin/linehaul-lanes";
import { Job, Shipment, ShipmentItem, WarehouseJob } from "@wearewarp/types/data-model";
import { Subscription } from "rxjs";

interface Stats {
  name?: string,
  shipments: number,
  pallets: number,
  age?: number,
  ts?: string,
}

interface DataStats {
  readyForOutbound: Stats,
  atDockHasRoute: Stats,
  atDockHasNoRoute: Stats,
  incoming: Stats,        // để biết được incoming today, tomorrow thì cần có ETA chính xác chứ ko dựa vào giờ dropoff dự kiến được
  scheduledInbound: Stats,
  pending: Stats,
  scheduledForOutboundJobs: number,
}

@Component({
  selector: '[linehaul-lane-v2-list-item]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
})
export class LinehaulLaneV2ListItem implements OnInit, OnDestroy {
  @Input() lane: ResponseLinehaulLaneListItem;
  isLoading = false;
  errMsg = '';

  private readonly planningService: PlanningService;
  private sub: Subscription;

  constructor() {
    this.planningService = new PlanningService(ApiService.instance);
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  routerLinkDetail(item) {
    return [Const.routePlanningLinehaulsV2, item.id]
  }

  routerLinkClient(client) {
    return [Const.routeAdminClientList, client.id];
  }

  onBtnRetry() {
    this.getData();
  }

  data: DataStats;

  private initDataStats(): DataStats {
    return {
      readyForOutbound: {shipments: 0, pallets: 0, age: 0},
      atDockHasRoute: {shipments: 0, pallets: 0, age: 0},
      atDockHasNoRoute: {shipments: 0, pallets: 0, age: 0},
      incoming: {shipments: 0, pallets: 0},
      scheduledInbound: {shipments: 0, pallets: 0},
      pending: {shipments: 0, pallets: 0},
      scheduledForOutboundJobs: 0,
    }
  }

  private getData() {
    this.isLoading = true;
    this.errMsg = '';
    this.sub?.unsubscribe();
    this.sub = this.planningService.listLinehaulLanePendingShipment(this.lane.id).subscribe(
      resp => {
        this.isLoading = false;
        this.processData(resp);
      }, err => {
        this.isLoading = false;
        this.errMsg = Utils.getErrorString(err) || 'Unable to fetch statistics data';
      }
    );
  }

  private listToDic<T extends {id?: string}>(list: T[]): {[key: string]: T} {
    const dic: {[key: string]: T} = {};
    for (let item of list) {
      dic[item.id] = item;
    }
    return dic;
  }

  private getCompleteDate(shipment: Shipment) {
    const log = shipment.statusChangeLog?.complete
    return log?.changeWhen ?? log?.when
  }

  private processData(data): void {
    this.data = this.initDataStats();
    const scheduledForOutboundJobs = [];
    if (!data) {
      return;
    }
    const {shipments, items, warehouseJobs, warehouseTasks, inboundLegs, jobs} = data;
    const dicJobs = this.listToDic<Job>(jobs);
    const dicItems = this.listToDic<ShipmentItem>(items);
    const dicInboundLegs = this.listToDic<Shipment>(inboundLegs);
    const dicInboundWarehouseJobs: {[key: string]: WarehouseJob[]} = {};
    for (let w of warehouseJobs) {
      if (w.level == 'shipment' && w.outboundLegId) {
        if (!dicInboundWarehouseJobs[w.outboundLegId]) {
          dicInboundWarehouseJobs[w.outboundLegId] = [];
        }
        dicInboundWarehouseJobs[w.outboundLegId].push(w);
      }
    }
    let processedIds: string[] = [];    // departed or scheduled for outbound
    const departed = shipments.filter(it => it.inboundStatus === 'departed');
    const departedJobIds: string[] = departed.map(it => it.lastJobId).filter(it => it);
    const departedJobs: Job[] = Utils.uniqElementsArray(departedJobIds).map(it => dicJobs[it]).filter(it => it);
    for (let job of jobs) {
      if (!departedJobIds.includes(job.id)) {
        scheduledForOutboundJobs.push(job);
      }
    }
    this.data.scheduledForOutboundJobs = scheduledForOutboundJobs.length;
    for (let job of departedJobs) {
      const jobShipments = shipments.filter(x => x.lastJobId == job.id);
      processedIds = [...processedIds, ...jobShipments.map(it => it.id)];
    }
    for (let job of scheduledForOutboundJobs) {
      const jobShipments = shipments.filter(x => x.lastJobId == job.id);
      processedIds = [...processedIds, ...jobShipments.map(it => it.id)];
    }
    const today = new Date().toISOString();
    const countStats = (stat: Stats, shipment) => {
      stat.shipments += 1;
      for (let itemId of shipment.itemIds ?? []) {
        const item = dicItems[itemId];
        const qty = item?.quantityItems?.length || item?.qty || 0;
        stat.pallets += qty;
      }
    }
    for (let _shipment of shipments) {
      const shipment = <Shipment>_shipment;
      const inboundJobs = dicInboundWarehouseJobs[shipment.id] ?? [];
      const inboundLegIds = Utils.uniqElementsArray(inboundJobs.map(it => it.inboundLegId).filter(it => it));
      const inboundLegs = inboundLegIds.map(it => dicInboundLegs[it]).filter(it => it);
      const dates = inboundLegs.map(it => this.getCompleteDate(it)).filter(it => it).sort();
      const inboundCompleteWhen = dates[0];
      switch (shipment.inboundStatus) {
        case 'readyForOutbound': {
          countStats(this.data.readyForOutbound, shipment);
          if (inboundCompleteWhen) {
            const days = DateUtil.diffDays(today, inboundCompleteWhen);
            this.data.readyForOutbound.age = Math.max(days, this.data.readyForOutbound.age);
          }
          break;
        }
        case 'arrivedAtWarehouse': {
          if (processedIds.includes(shipment.id)) break;
          const hasRoute = !!shipment.lastJobId;
          if (hasRoute) {
            countStats(this.data.atDockHasRoute, shipment);
          } else {
            countStats(this.data.atDockHasNoRoute, shipment);
          }
          if (inboundCompleteWhen) {
            const days = DateUtil.diffDays(today, inboundCompleteWhen);
            if (hasRoute) {
              this.data.atDockHasRoute.age = Math.max(days, this.data.atDockHasRoute.age)
            } else {
              this.data.atDockHasNoRoute.age = Math.max(days, this.data.atDockHasNoRoute.age)
            }
          }
          break;
        }
        case 'pickedUp': {
          if (processedIds.includes(shipment.id)) break;
          countStats(this.data.incoming, shipment);
          break;
        }
        case 'scheduledToPickup': {
          if (processedIds.includes(shipment.id)) break;
          countStats(this.data.scheduledInbound, shipment);
          break;
        }
        case 'pending':
        case 'readyForPickup': {
          if (processedIds.includes(shipment.id)) break;
          countStats(this.data.pending, shipment);
          break;
        }
      }
    }
  }

  displayCount(num: number, unitSingular: string, unitPlural?: string) {
    return Utils.displayCount(num, unitSingular, unitPlural);
  }

}