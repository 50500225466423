<div class="adjust-shipment">
  <div class="wrap-container">
    <div class="title">Create merged Order</div>
    <div class="merge-shipment">
      <div class="list-route-selected">
        <div class="bottom20">
          <div><b>Customer</b>: Warp Technology Co</div>
          <div><b>Shipment Type</b>: LTL</div>
          <div><b>Revenue</b>: $0</div>
        </div>
        <nz-table #nzTable [nzData]="shipments" nzBordered="true" nzSize="small" [nzFrontPagination]="false"
        [nzShowPagination]="false" [nzLoading]="isLoading">
          <thead>
            <tr>
              <th nzWidth="250px">Shipment ID</th>
              <th>Pickup Address</th>
              <th>Dropoff Address</th>
              <th>Customer</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <a [routerLink]="[routeAdminShipmentList, shipment?.orderId]" target="_blank">
                    {{showShipmentCode(shipment)}}
                  </a>
                </div>
              </td>
              <td>
                <b>{{shipment?.pickInfo?.locationName}}</b><br />
                <div>{{getLocationAddress(shipment.pickInfo)}}</div>
                <div class="shipment-mode">{{getDeliveryInfoTime(shipment?.pickInfo) || "N/A"}}</div>
                <div class="label-info referenceNo">Reference No: {{getReferenceNo(shipment?.pickInfo)}}</div>
                <div *ngIf="shipment?.pickInfo?.serviceOptions?.length">
                  <span>Service Options: </span>
                  <code class="so-val" *ngFor="let r of shipment?.pickInfo?.serviceOptions">{{ getServiceOptionName(r) }}</code>
                </div>
              </td>
              <td>
                <b>{{shipment?.dropInfo?.locationName}}</b><br />
                <div>{{getLocationAddress(shipment.dropInfo)}}</div>
                <div class="shipment-mode">{{getDeliveryInfoTime(shipment?.dropInfo) || "N/A"}}</div>
                <div>
                  <div class="label-info referenceNo">Reference No: {{getReferenceNo(shipment?.dropInfo)}}</div>
                </div>
                <div *ngIf="shipment?.dropInfo?.serviceOptions?.length">
                  <span>Service Options: </span>
                  <code class="so-val" *ngFor="let r of shipment?.dropInfo?.serviceOptions">{{ getServiceOptionName(r) }}</code>
                </div>
              </td>
              <td>
                <b>{{shipment?.client?.name}}</b>
              </td>
              <td>
                {{getStatusOrder(shipment.status)}}
              </td>
            </tr>
            <tr>
              <td colspan="5">
                <div class="flex-space-between" style="margin-top: 15px; margin-bottom: 15px;">
                  <div>
                    <b>The shipments have the same pickup and drop-off location as {{showShipmentCode(shipment)}} on the same schedule. Please choose and merge</b>
                  </div>
                  <div><a (click)="getShipmentList()"><span class="right5" nz-icon nzType="reload" nzTheme="outline"></span>Reload</a></div>
                </div>
            </td>
            </tr>
            <tr *ngFor="let item of nzTable.data">
              <td [nzChecked]="setOfCheckedId.has(item.id)"
                (nzCheckedChange)="onItemChecked(item.id, $event)"
              >
                <span class="left5">
                  <a [routerLink]="[routeAdminShipmentList, item?.orderId]" target="_blank">
                    {{showShipmentCode(item)}}
                  </a>
                </span>
              </td>
              <td>
                <b>{{item?.pickInfo?.locationName}}</b><br />
                <div>{{getLocationAddress(item.pickInfo)}}</div>
                <div class="shipment-mode">{{getDeliveryInfoTime(item?.pickInfo) || "N/A"}}</div>
                <div class="label-info referenceNo">Reference No: {{getReferenceNo(item?.pickInfo)}}</div>
                <div *ngIf="item?.pickInfo?.serviceOptions?.length">
                  <span>Service Options: </span>
                  <code class="so-val" *ngFor="let r of item?.pickInfo?.serviceOptions">{{ getServiceOptionName(r) }}</code>
                </div>
                
              </td>
              <td>
                <b>{{item?.dropInfo?.locationName}}</b><br />
                <div>{{getLocationAddress(item.dropInfo)}}</div>
                <div class="shipment-mode">{{getDeliveryInfoTime(item?.dropInfo) || "N/A"}}</div>
                <div class="label-info referenceNo">Reference No: {{getReferenceNo(item?.dropInfo)}}</div>
                <div *ngIf="item?.dropInfo?.serviceOptions?.length">
                  <span>Service Options: </span>
                  <code class="so-val" *ngFor="let r of item?.dropInfo?.serviceOptions">{{ getServiceOptionName(r) }}</code>
                </div>
              </td>
              <td>
                <b>{{item?.client?.name}}</b>
              </td>
              <td>
                <span class="status" [class]="'status-' + item.status">{{ getStatusOrder(item.status) }}</span>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
  <div class="bottom-bar">
    <div class="wrapper">
      <div class="button-controls">
        <button nz-button nzType="default" nzDanger class="cancel-btn" (click)="onCancel()">Cancel</button>
        <button nz-button nzType="primary" class="submit-btn" (click)="onSubmit()" [disabled]="!canSubmit()"
          [nzLoading]="isSubmitting">Merge</button>
      </div>
      <div class="info">
      </div>
    </div>
  </div>
</div>



