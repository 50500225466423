import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { DeliveryInfo } from "@wearewarp/types/data-model";


@Component({
    selector: '[short-location-info]',
    templateUrl: './short_location.html',
    styleUrls: ['../styles.scss']
})
export class ShortLocationInfo extends BaseComponent {
    @Input() info: DeliveryInfo = null

    getRouterLinkLocation() {
      if (!this.info.warehouseId) return undefined;
      return [Const.routeAdminLocationList]
    }
  
    getRouterLinkLocationQueryParams() {
      if (!this.info.warehouseId) return undefined;
      return {filter: JSON.stringify({id: this.info.warehouseId})}
    }
}