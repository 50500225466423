<div class="font-bold bottom10 flex-space-between">
  <div>Items</div>
</div>
<div
  *ngIf="shipmentItems && shipmentItems.length"
  style="border: solid 1px #eee; background-color: white"
  class="bottom10"
>
  <div class="flex shipping-item font-medium">
    <div style="width: 150px">Code</div>
    <div style="width: 160px">Name</div>
    <div style="width: 150px">Dimension</div>
    <div style="width: 100px">Weight</div>
    <div style="width: 430px">Warehouse Task</div>
  </div>
  <div class="flex shipping-item" *ngFor="let item of shipmentItems">
    <div style="width: 150px;">
      <code *ngIf="item.barcodes?.length">{{barcodeText(item.barcodes)}}</code>
      <code *ngIf="!item.barcodes?.length" class="not-identified">N/A</code>
    </div>

    <div style="width: 160px;">{{item?.name}}</div>
    <div style="width: 150px;">
      {{item.length || '-'}} x {{ item.width || '-'}} x {{item.height  || '-'}} {{ item.sizeUnit }}
    </div>

    <div style="width: 100px;">
      <span class="font-medium" *ngIf="item.actualWeight">{{ item.actualWeight }} {{item.actualUnit || item.weightUnit}}</span>
      <span *ngIf="!item.actualWeight && item.weightPerUnit" class="not-identified">{{ item.weightPerUnit.toFixed(0) }} {{ item.weightUnit}}</span>
    </div>

    <div style="width: 430px;">
      <ng-container *ngFor="let task of item?.warehouseTasks">
        <ng-container *ngIf="task.status === 'completed'">
          {{ getTaskLabel(task) }}: <b>{{ getUserName(task) }}</b> - {{ getTaskDate(task) }}<br/>
        </ng-container>
        <ng-container *ngIf="task.status !== 'completed'">
          {{ getTaskLabel(task) }}: -<br/>
        </ng-container>
      </ng-container>
    </div>

    <div *ngIf="getImages(item)?.length" class="flex">
      <div *ngFor="let image of getImages(item)" class="group-image">
        <img class="compact clickable" [src]="image?.fullPath" (click)="showItemPhoto(image?.fullPath)">
      </div>
    </div>

    <div style="text-align: end; flex: 1">
      <button nz-button nzType="text" (click)="onViewItemsHistory(item)">
        <i nz-icon nzType="history" nzTheme="outline"></i>
        Item history
      </button>
    </div>
  </div>
</div>