<div #formComp form-shipment-location type="PICKUP" [model]="formModel" [dedicatedLaneData]="dedicatedLane"></div>

<!-- 
<div #formLocation form-shipment-location [viewTemplate]="tplLocation"></div>
<ng-template #tplLocation>
  <form [formGroup]="formLocation.formInput" nz-form>
    <ng-container *ngFor="let key of ['locationName', 'warehouseId']">
      <input nz-input [formControlName]="key">
    </ng-container>
  </form>
</ng-template>
 -->