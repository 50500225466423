import { Component, ViewChild } from '@angular/core';
import { FormDataShipmentEntryDraftSingle, FormDataShipmentLocation } from '@wearewarp/types-server-admin/form-data/shipment-entry';
import { FormShipmentLocation } from '../../forms/shipment-location';
import { ComponentForm } from '../../../interface';
import { BaseForm } from '@app/admin/base/form-base';
import { ShipmentEntryTabContent } from '../../tab-content-component';
import { DedicatedLaneData } from '@app/admin/dedicated-lanes/dedicated-lane.interface';

@Component({
  selector: '[shipment-entry-single-pickup]',
  templateUrl: './index.html'
})
export class ShipmentEntrySinglePickup extends ShipmentEntryTabContent implements ComponentForm<FormDataShipmentLocation> {

  @ViewChild('formComp') formComp: FormShipmentLocation;
  
  getForm(): BaseForm<FormDataShipmentLocation> {
    return this.formComp;
  }

  get modelDraft(): FormDataShipmentEntryDraftSingle {
    return super.modelDraft;
  }

  get formModel() {
    return this.modelDraft?.pickInfo;
  }
}