
import { Component, Input } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const as WarpConst, WarpId } from '@wearewarp/universal-libs';
import { Const } from "@const/Const";
import { DialogService } from '@dialogs/dialog.service';
import { PalletHistory } from '@app/admin/components/pallet-history';
import { WarehouseTask } from '@wearewarp/types/data-model';
import { DateUtil } from '@services/date-utils';

@Component({
  selector: '[shipment-warehouse-item-content]',
  templateUrl: './item_content.html',
  styleUrls: ['./item_content.scss']
})
export class ShipmentWarehouseItemContent extends BaseComponent {
  _item: any;
  shipmentItems: any[] = [];
  userInfos: any = {};

  @Input() set item(value) {
    this._item = value;
    this.shipmentItems = value?.shipmentItems || []
    this.getUserInfo();
  }

  async getUserInfo() {
    const warehouseTasks = this.shipmentItems.map(it => it.warehouseTasks).flat();
    const userIds = [];
    for(let it of warehouseTasks) {
      const userId = it.completeBy?.byId;
      if(userIds.includes(userId)) continue;
      if(userId) userIds.push(userId);
    }
    const urls = userIds.map(id => `${Const.APIURI_USERS}/${id}`);
    //không sử dụng concurrentGET vì nếu có 1 user bị xoá thì sẽ ảnh hưởng đến toàn bộ các query khác.
    const result = await Promise.allSettled(urls.map(url => this.api.GET(url).toPromise()))
    let resp = result.filter(item => item.status == 'fulfilled').map((item: any) => item.value.data);
    for(let it of resp) {
      this.userInfos[it.id] = it;
    }
  }

  get parentId() {
    return this._item?.parent?.id
  }

  get parentWarpId() {
    return WarpId.showShipmentCode(this._item?.parent)
  }

  onViewItemsHistory(item) {
    DialogService.openDialog(PalletHistory, {
      nzComponentParams: {
        itemId: item.id
      },
      nzWidth: '630px',
      nzStyle: {
        top: '20px'
      },
      nzTitle: `Warp ID: ${item.warpId} - Item history`
    })
  }

  getImages(item) {
    if (!item?.warehouseTasks?.length) return [];
    const taskUploadPhotos = item?.warehouseTasks.filter(item => item.type == WarpConst.WarehouseTaskType.uploadProductPhoto);
    if (!taskUploadPhotos?.length) return [];

    let lists = [];
    taskUploadPhotos.map(item => {
      if (item?.data?.images?.length) lists = [...lists, ...item?.data?.images];
    });

    for(let it of lists) {
      it.fullPath = this.attachedFileUrl(it);
    }
    return lists;
  }

  showItemPhoto(url) {
    this.showDialog(`<img src=${url} width="100%" />`);
  }

  getTaskLabel(task: WarehouseTask) {
    const tasks = {
      [`${Const.WarehouseTaskType.assignBarcode}`]: 'Task assign',
      [`${Const.WarehouseTaskType.scanPallet}`]: 'Task scan',
      [`${Const.WarehouseTaskType.uploadProductPhoto}`]: 'Task photo',
      [`${Const.WarehouseTaskType.addWeight}`]: 'Task weight',
      [`${Const.WarehouseTaskType.markLoaded}`]: 'Task load',
    }
    return `${tasks[task.type]} (${task.stage === WarpConst.WarehouseTaskStage.inbound ? 'IN' : 'OUT'})`;
  }

  getTaskDate(task: WarehouseTask) {
    const time = task?.completeBy?.when || task?.update?.when;
    return DateUtil.format(time, 'DD/MM/YYYY HH:mm');
  }

  getUserName(task: WarehouseTask) {
    const user = this.userInfos[task.completeBy?.byId || task.update?.byId];
    return user ? `${user.firstName} ${user.lastName}` : '';
  }
}