<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <div *ngFor="let key of ['instructions']">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
      <nz-form-item>
        <nz-form-control>
          <textarea nz-input [formControlName]="key"
            [placeholder]="getPlaceHolder(key)" 
            [nzAutosize]="{minRows: 2, maxRows: 4}"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
      
    </div>
    
    <form-input-upload-images #uploadImagesForm [visible]="!onProgress"
      (fileSelectedChange)="onFileImageSelectedChange('instructionImgs', $event)"
      [uploadedImages]="model?.instructionImgs?.files ?? []"
      (uploadedImagesChange)="onFileUploadedImageChange('instructionImgs', $event)"
    >
    </form-input-upload-images>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>