import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FilterFieldDeclaration, FilterLayout } from "@app/admin/components/filter-layout";
import { DataRepoLinehaulLane } from "@app/data-repo/linehaul-lane";
import to from 'await-to-js';

interface DataFetch {
  isLoading?: boolean,
  list: any[],
}

@Component({
  selector: '[linehaul-lane-v2-list-filter]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
})
export class LinehaulLaneV2ListFilter implements OnInit, AfterViewInit {
  // for filter layout
  @ViewChild('filter') filter: FilterLayout;
  @ViewChild('tplFilter_input') tplFilter_input: TemplateRef<any>;
  @ViewChild('tplFilter_select') tplFilter_select: TemplateRef<any>;
  groups: string[] = [];

  constructor(
    private dataRepoLinehaul: DataRepoLinehaulLane,
  ) {
  }

  ngOnInit(): void {
    this.fetchDataForSelection();
  }

  ngAfterViewInit(): void {
    this.filter.ngHostAfterViewInit(this.getFilterFields())
  }

  getFilterFields(): FilterFieldDeclaration[] {
    return [
      {
        key: 'name',
        declaration: { label: 'Name', notAcceptEmpty: true },
        templateInput: this.tplFilter_input,
      },
      {
        key: 'marketFrom',
        declaration: { label: 'From Market', notAcceptEmpty: true },
        templateInput: this.tplFilter_select,
      },
      {
        key: 'marketTo',
        declaration: { label: 'To Market', notAcceptEmpty: true },
        templateInput: this.tplFilter_select,
      },
      {
        key: 'vehicles',
        declaration: { label: 'Vehicle', notAcceptEmpty: true },
        templateInput: this.tplFilter_select,
      },
      {
        key: 'group',
        declaration: { label: 'Group', notAcceptEmpty: true },
        templateInput: this.tplFilter_select,
      },
    ];
  }

  onInputEnter(key: string, value: string) {
    this.onChange();
  }

  onChange() {
    this.filter.onChange();
  }

  private dataFetch: {[key: string]: DataFetch} = {
    group: {
      list: [],
    },
    marketFrom: {
      list: [],
    },
    marketTo: {
      list: [],
    },
    vehicles: {
      list: [],
    }
  }

  private async fetchDataForSelection() {
    for (let key of Object.keys(this.dataFetch)) {
      this.dataFetch[key].isLoading = true;
    }
    const [err, data] = await to(this.dataRepoLinehaul.getDataForFilter());
    if (err) {
      console.error(err);
    }
    this.dataFetch.group.list = [...data.groups, 'NO GROUP'];
    this.dataFetch.marketFrom.list = [...data.fromMarket];
    this.dataFetch.marketTo.list = [...data.toMarket];
    this.dataFetch.vehicles.list = data.vehicles;
    for (let key of Object.keys(this.dataFetch)) {
      this.dataFetch[key].isLoading = false;
    }
  }

  isLoading(key: string): boolean {
    switch (key) {
      case 'group':
      case 'marketFrom':
      case 'marketTo':
      case 'vehicles':
        return this.dataFetch[key]?.isLoading ?? false;
      default:
        return false;
    }
  }

  getListForSelection(key: string) {
    switch (key) {
      case 'group':
      case 'marketFrom':
      case 'marketTo':
      case 'vehicles':
        return this.dataFetch[key]?.list ?? [];
      default:
        return [];
    }
  }

  getLabelForSelection(key: string, index: number) {
    const item = (this.dataFetch[key]?.list ?? [])[index];
    switch (key) {
      case 'group':
      case 'marketFrom':
      case 'marketTo':
        return item;
      case 'vehicles':
        return item.name;
      default:
        return '';
    }
  }

  getValueForSelection(key: string, index: number) {
    const item = (this.dataFetch[key]?.list ?? [])[index];
    switch (key) {
      case 'group':
      case 'marketFrom':
      case 'marketTo':
        return item;
      case 'vehicles':
        return item.code;
      default:
        return '';
    }
  }

  canSelectMultiple(key: string): boolean {
    switch (key) {
      case 'vehicles':
        return true;
      default: 
        return false;
    }
  }



}