import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Const } from "@const/Const";
import { BaseComponent } from "@abstract/BaseComponent";
import { ulid } from 'ulid';

@Component({
    selector: '[shipment-new-leg-form]',
    templateUrl: './index.html',
    styleUrls: ['../styles.scss', './style.scss']
})
export class ShipmentNewLegForm extends BaseComponent implements OnChanges {
    @Input() from: any = null
    @Input() parent: any = null
    @Input() items
    @Input() siblings: any[] = null

    warehouses = []
    filteredWarehouses = []
    locationInputValue = null

    chooseOption: 'deliver_item' | 'return_item' | 'empty_pallet' = null;
    emptyPalletsQty: number;

    @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSave: EventEmitter<any> = new EventEmitter<any>();

    public dropoffLocation: any = null
    public pickupInfo: any = null
    public dropoffInfo: any = null
    public canComplete: boolean = false
    public finalDropoff: any = null
    public firstPickup: any = null
    public inProgress = false
    public selectedItems: any[] = []

    ngOnInit(): void {
        this.loadWarehouses()
    }

    setItems(items: any[]) {
        this.selectedItems = items.filter(it => it.checked);
        this.checkCanComplete();
    }

    isSameLocation(addr1, addr2) {
        if (!addr1?.metadata?.latitude || !addr1?.metadata?.longitude) return false
        if (!addr2?.metadata?.latitude || !addr2?.metadata?.longitude) return false
        return Math.abs(addr1.metadata.latitude - addr2.metadata.latitude) < .0001
            && Math.abs(addr1.metadata.longitude - addr2.metadata.longitude) < .0001
    }

    ngOnChanges(): void {
        console.log('from', this.from)
        const { contacts, addr, warehouseId, locationName, instructions, note, instructionImgs } = this.from
        const primaryContact = (contacts || []).filter(it => it?.type === 'primary')[0] || {fullName: null, phone: null, phoneExtension: null, email: null}
        const secondaryContacts = (contacts || []).filter(it => it?.type === 'secondary')
        this.pickupInfo = {
            addr,
            type: 'PICKUP',
            instructions,
            warehouseId,
            locationName,
            note,
            primaryContact,
            secondaryContacts,
            instructionImgs,
        }
        let warehouse = this.warehouses.find(warehouse => this.from.warehouseId === warehouse.id);
        if (warehouse?.pickDetails) {
            this.pickupInfo.instructions = warehouse.pickDetails.instructions;
            this.pickupInfo.note = warehouse.pickDetails.note;
            this.pickupInfo.instructionImgs = {
                files: warehouse?.pickDetails?.instructionImgs,
                fileIds: warehouse?.pickDetails?.instructionImgIds,
            };
        }
        this.dropoffInfo = {
            addr: null,
            type: 'DROPOFF',
            instructions: null,
            note: null,
            primaryContact: {fullName: null, phone: null, email: null},
            secondaryContacts: []
        }
        const { deliveryInfos } = this.parent || {}
        const dropoff = (deliveryInfos || []).filter(it => it.type === 'DROPOFF')[0]
        const pickup = (deliveryInfos || []).filter(it => it.type === 'PICKUP')[0]
        if (this.isSameLocation(pickup?.addr, this.from?.addr)) {
            // copy info from main shipment
            this.pickupInfo.windows = pickup.windows
            this.pickupInfo.appointmentInfo = pickup.appointmentInfo
            this.pickupInfo.requiresAppointment = pickup.requiresAppointment
            this.pickupInfo.refNums = pickup.refNums
        }
        const finalDropoff = (this.from.id === dropoff.id || this.from.warehouseId === dropoff.warehouseId) ? null : dropoff
        const firstPickup = (this.from.id === pickup.id || this.from.warehouseId === pickup.warehouseId) ? null : pickup
        if (finalDropoff) {
            const pc = (finalDropoff.contacts || []).filter(it => it?.type === 'primary')[0] || {fullName: null, phone: null, phoneExtension: null, email: null}

            this.finalDropoff = {
                id: finalDropoff.warehouseId,
                name: finalDropoff.locationName,
                pickAddr: finalDropoff.addr,
                contactName: pc.fullName,
                phone: pc.phone,
                email: pc.email,
                value: finalDropoff.locationName || `${finalDropoff.addr.city}, ${finalDropoff.addr.state} ${finalDropoff.addr.zipcode}`,
                instructions: finalDropoff.instructions,
                note: finalDropoff.note,
                requiresAppointment: finalDropoff.requiresAppointment,
                instructionImgs: finalDropoff?.instructionImgs
            }
            let firstFinalLeg = true
            if (this.siblings) {
                for (let sibling of this.siblings) {
                    const siblingDropoff = (sibling.deliveryInfos || []).filter(it => it.type === 'DROPOFF')[0]
                    if (this.isSameLocation(siblingDropoff?.addr, dropoff?.addr)) {
                        firstFinalLeg =  false
                    }
                }
            }
            if (firstFinalLeg) {
                this.finalDropoff.windows = finalDropoff.windows
                this.finalDropoff.appointmentInfo = finalDropoff.appointmentInfo
                this.finalDropoff.requiresAppointment = finalDropoff.requiresAppointment
                this.finalDropoff.refNums = finalDropoff.refNums
            }
        }
        if (firstPickup) {
            const pc = (firstPickup.contacts || []).filter(it => it?.type === 'primary')[0] || {fullName: null, phone: null, phoneExtension: null, email: null}

            this.firstPickup = {
                id: firstPickup.warehouseId,
                name: firstPickup.locationName,
                pickAddr: firstPickup.addr,
                contactName: pc.fullName,
                phone: pc.phone,
                email: pc.email,
                value: firstPickup.locationName || `${firstPickup.addr.city}, ${firstPickup.addr.state} ${firstPickup.addr.zipcode}`,
                instructions: firstPickup.instructions,
                note: firstPickup.note,
                instructionImgs: firstPickup?.instructionImgs
            }
        }
    }

    onClickCancel() {
        this.onCancel?.emit()
    }

    onClickSave() {
        // prepare data for submitting
        this.pickupInfo.contacts = []
        this.dropoffInfo.contacts = []
        if (this.pickupInfo.primaryContact.fullName || this.pickupInfo.primaryContact.phone || this.pickupInfo.primaryContact.email) {
            this.pickupInfo.contacts.push(Object.assign({type: 'primary'}, this.pickupInfo.primaryContact))
        }
        if (this.dropoffInfo.primaryContact.fullName || this.dropoffInfo.primaryContact.phone || this.dropoffInfo.primaryContact.email) {
            this.dropoffInfo.contacts.push(Object.assign({type: 'primary'}, this.dropoffInfo.primaryContact))
        }
        this.pickupInfo.id = ulid()
        this.dropoffInfo.id = ulid()

        const deliveryInfos = [this.pickupInfo, this.dropoffInfo]
        const warehouseIds = deliveryInfos.map(it => it.warehouseId).filter(it => it)
        const itemIds = this.selectedItems.map(it => it.id)
        const sameLocation = this.pickupInfo?.warehouseId && this.pickupInfo?.warehouseId === this.dropoffInfo?.warehouseId
        const duplicated: any = {
            deliveryInfos,
            parentId: this.parent.id,
            clientId: this.parent.clientId,
            shipmentType: Const.ShipmentTypes.lessThanTruckload,
            shipmentTransitType: sameLocation ? Const.ShipmentTransitType.layover : Const.ShipmentTransitType.leg,
            isCrossDock: true,
            id: ulid(),
            warehouseIds,
            tempRange: this.parent.tempRange,
            itemIds,

        }
        if(this.emptyPalletsQty) duplicated.palletQty = this.emptyPalletsQty;

        this.inProgress = true
        const url = Const.APIV2('shipments/add_sub_shipment')
        this.api
            .POST(url, {
                parentId: this.parent.id,
                shipments: [duplicated]
            }).subscribe(
            (resp) => {
                const created = resp.data.list_data[0]
                this.onSave.emit(created)
                this.inProgress = false
            },
            (error) => {
                this.showErr(error)
                this.onSave.emit(null)
                this.inProgress = false
            }
        )
    }

    onLocationChange(event) {
        console.log('onLocationChange', event)
    }

    onLocationSelected(event, item) {
        if (!event.isUserInput) {
            return;
        }
        this.dropoffLocation = item
        this.dropoffInfo.addr = item.address || item.pickAddr || item.addr
        this.dropoffInfo.warehouseId = item.id
        this.dropoffInfo.locationName = item.name
        this.dropoffInfo.requiresAppointment = item.requiresAppointment || false

        this.dropoffInfo.primaryContact.fullName = item.contactName
        this.dropoffInfo.primaryContact.phone = item.phone
        this.dropoffInfo.primaryContact.phoneExtension = item?.phoneExtension
        this.dropoffInfo.primaryContact.email = item.email
        this.dropoffInfo.windows = item.windows
        this.dropoffInfo.appointmentInfo = item.appointmentInfo
        this.dropoffInfo.refNums = item.refNums
        this.dropoffInfo.instructions = item.instructions || item.dropDetails?.instructions
        this.dropoffInfo.note = item.note || item.dropDetails?.note
        this.dropoffInfo.instructionImgs = item.instructionImgs ?? {
            files: item?.dropDetails?.instructionImgs,
            fileIds: item?.dropDetails?.instructionImgIds,
        };

        this.checkCanComplete()
    }

    checkCanComplete() {
        if (!this.dropoffInfo?.addr) {
            this.canComplete = false
            return
        }

        if (!this.chooseOption) {
            this.canComplete = false
            return
        }

        if (!this.selectedItems.length && ['deliver_item', 'return_item'].includes(this.chooseOption)) {
            this.canComplete = false
            return
        }

        if (this.chooseOption === 'empty_pallet' && !this.emptyPalletsQty) {
            this.canComplete = false
            return
        }
        
        this.canComplete = true
    }

    onChangeEmptyPalletsQty(event) {
        this.checkCanComplete()
    }

    onChangeChooseOption(event) {
        this.checkCanComplete()
    }

    getWarehouseLink() {
        // event?.preventDefault();
        // event?.stopPropagation();
        if(!this.dropoffInfo?.locationName) return;
        return `${this.routeAdminLocationList}?search=${this.dropoffInfo.locationName}`;
    }

    onChangeLocationSearch(value: string): void {
        const matchWH = (wh) => {
            const v = value.toLowerCase()
            if (wh.warpId == v) return true
            if (wh.name?.toLowerCase()?.indexOf(v) !== -1) return true
            if (wh.pickAddr?.state?.toLowerCase()?.indexOf(v) !== -1) return true
            if (wh.pickAddr?.city?.toLowerCase()?.indexOf(v) !== -1) return true
            if (wh.pickAddr?.street?.toLowerCase()?.indexOf(v) !== -1) return true
            return false
        }
        this.filteredWarehouses = this.warehouses.filter(matchWH);
    }

    loadWarehouses() {
        const url = `${Const.APIURI_WAREHOUSES}?limit=-1&filter=${JSON.stringify({warehouseType: 'crossdock'})}`
        this.api.GET(url).subscribe((res) => {
            this.warehouses = res.data.list_data
            this.filteredWarehouses = this.warehouses
            let warehouse = this.warehouses.find(warehouse => this.from.warehouseId === warehouse.id);
            if (warehouse?.pickDetails) {
                this.pickupInfo.instructions = warehouse.pickDetails.instructions;
                this.pickupInfo.note = warehouse.pickDetails.note;
                this.pickupInfo.instructionImgs = {
                    files: warehouse?.pickDetails?.instructionImgs ?? [],
                    fileIds: warehouse?.pickDetails?.instructionImgIds ?? [],
                }
            }
        })
    }
}