import to from 'await-to-js';
import { Component, Input } from "@angular/core";
import * as pdfjsLib from 'pdfjs-dist';
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { DateUtil } from "@services/date-utils";
import { Log } from "@services/log";
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { NotesComponentEventFetchDataDone, NotesComponentEventItemAdded, NotesComponentEventItemRemoved } from '@app/admin/components/notes/interface';
import { AttachedFile, Shipment } from '@wearewarp/types/data-model';
import { AttachedFileUtil } from '@services/attached-file-util';
pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/mozilla/pdfjs-2.13.216/pdf.worker.min.js';
import { makeOrderRevenueSummary } from "@wearewarp/js-const-finance";

interface ConfirmPodData {
  orderId?: string,
  isInvoiceWithoutPOD?: boolean,
  status?: string,
}

@Component({
  selector: '[confirm-pod]',
  templateUrl: './index.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './index.scss']
})
export class ConfirmPod extends BaseDialog {
  @Input() data: ConfirmPodData = {};
  onDone: (responseData) => void = () => {}

  public order: any = {};
  private isMissingPOD: boolean = true;
  private totalPOD: number = 0;
  public isLoading = false;
  public isCarrierBillMatches = false;
  public isGeneratingInvoice = false;
  public podGroups: any = [];
  public isLoadingChildren: boolean = false;
  private isZeroRevenue: boolean = false;

  public get hasInvoice(): boolean {
    return !!this.order?.invoiceFileId;
  }

  private get orderId() {
    return this.data?.orderId
  }

  get isInvoiceWithoutPOD() {
    return this.data?.isInvoiceWithoutPOD
  }

  get isOrderCanceled() {
    return this.data?.status === WarpConst.OrderStatusNew.canceled;
  }

  public get isMultiStop(): boolean { return this.order?.isMultiStop ?? false }
  // public get isParentSubShipment(): boolean { return this.order?.isParentSubShipment ?? false }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
    this.checkCarrierBillMatches();
  }

  onBtnCancel() {
    this.closeDialog();
  }

  // public isProcessing: boolean = false;
  checkCarrierBillMatches() {
    this.isLoading = true;
    this.api.GET(`${Const.APIV2('orders')}/${this.orderId}/check_carrier_bill_matches`).subscribe(
      resp => {
        this.isLoading = false;
        if (resp?.data) {
          this.isCarrierBillMatches = resp.data.isMatched
        }
      }, err => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  onGenerateInvoice() {
    if (!this.canGenerateInvoice) return;
    let body: any = {};
    if (this.order?.invoiceFileId || this.order?.invoice?.id) body = { forceReGenerate: true };
    this.isGeneratingInvoice = true;
    const url = `v2/${Const.APIURI_ORDERS}/invoice/${this.order.id}`;
    this.api.POST(url, body).subscribe(
      resp => {
        this.isGeneratingInvoice = false;
        this.onDone(resp.data);
        this.closeDialog();
      }, err => {
        this.isGeneratingInvoice = false;
        this.showErr(err);
      }
    );
  }

  onConfirmGenerateInvoice() {
    if (this.isZeroRevenue) {
      this.confirmYesNo(`Are you sure to create the invoice with zero revenue?`, () => {
        this.onGenerateInvoice();
      });
    } else {
      this.onGenerateInvoice();
    }
  }

  private mapShipments: any = {};
  private async initPodGroups(groups: any[]) {
    let check = true; // đủ pod = true, thiếu pod = false
    groups.map(item => {
      let shipments = item?.shipments || [];
      if (!item?.podInfo?.checkRequire && ![WarpConst.ShipmentStatus.canceled, WarpConst.ShipmentStatus.disposed].includes(item?.status)) check = false;
      for (let i = 0; i < shipments?.length; i++) {
        let { id, lastJobId, ...info } = shipments[i];
        if (this.mapShipments[id]) {
          continue;
        } else {
          let obj = {
            shipmentId: id,
            ...info,
            jodId: lastJobId,
            podArr: [],
            podRequired: {
              pickup: info?.pickSetting?.photoRequired,
              dropoff: info?.dropSetting?.photoRequired,
            },
            podMissing: {
              pickup: false,
              dropoff: false,
            }
          }
          this.podGroups.push(obj);
          this.mapShipments[id] = obj;
        }
      }
    });
    this.isMissingPOD = !check;
  }

  isCanceled(podGroup): boolean {
    return podGroup.status == Const.OrderStatus.canceled;
  }

  private addToPodGroups(shipmentId: string, podArr: Array<any>, task) {
    this.totalPOD += podArr?.length || 0;
    for (let i = 0; i < this.podGroups.length; i++) {
      if (this.podGroups[i].shipmentId == shipmentId) {
        this.podGroups[i].podArr = [...this.podGroups[i].podArr, ...podArr];
        this.podGroups[i].jobId = task?.jobId;
        if (task?.type == Const.TaskType.PICKUP && this.podGroups[i]?.podRequired?.pickup) {
          this.podGroups[i].podMissing.pickup = this.podGroups[i].podArr.filter(pod => pod.taskType == task?.type).length == 0;
        }
        else if (task?.type == Const.TaskType.DROPOFF && this.podGroups[i]?.podRequired?.dropoff) {
          this.podGroups[i].podMissing.dropoff = this.podGroups[i].podArr.filter(pod => pod.taskType == task?.type).length == 0;
        }
        break;
      }
    }
  }

  private reloadPodInfos(groups: any[]) {
    groups.map(item => {
      let { tasks = [] } = item;
      tasks.map(task => {
        this.preparePodUrlForTask(task);
        let { shipmentId, jobId, podArr = []} = task;
        if (podArr.length) {
          podArr = podArr.map(pod => {
            pod.taskId = task.id;
            pod.jobId = jobId;
            pod.taskType = task.type;
            return pod
          });
        }
        this.addToPodGroups(shipmentId, podArr, task);
      });
    });
  }

  public canGenerateInvoice: boolean = false;

  private checkCanGenerateInvoice() {
    if (this.isOrderCanceled) {
      this.canGenerateInvoice = true;
      return;
    }
    let check = this.isInvoiceWithoutPOD ? true : !this.isMissingPOD;
    if (!this.totalPOD) {
      this.canGenerateInvoice = check;
    } else {
      if (check && this.podGroups?.length) {
        for (let podGroup of this.podGroups) {
          if (this.isCanceled(podGroup)) {
            continue;
          }
          for (let pod of podGroup.podArr) {
            if (!this.isConfirmPOD(pod)) {
              check = false;
            }
          }
        }
      }
      this.canGenerateInvoice = check;
    }
  }

  private getData() {
    this.isLoading = true;
    this.podGroups = [];
    const url = `v2/${Const.APIURI_ORDERS}/${this.orderId}/pod`;
    this.api.GET(url).subscribe(
      async (resp) => {
        if (resp?.data) {
          this.order = resp?.data?.order;
          let list_data = resp?.data?.podGroups || [];
          await this.initPodGroups(list_data);
          this.getChildren();
          this.reloadPodInfos(list_data);
        } else {
          this.showErr(resp.message);
        }
        Log.d('podGroups: ', this.podGroups);
        this.checkCanGenerateInvoice();
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  getChildren() {
    const shipmentId = this.order?.shipmentIds?.[0];
    if (!shipmentId) return;
    this.isLoadingChildren = true;
    this.api.GET(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${shipmentId}/childrens`).subscribe((response) => {
      const children: Shipment[] = response.data.list_data;
      this.getOrderRevenueSummary(children);
      this.isLoadingChildren = false;
    }, err => {
      this.isLoadingChildren = false;
      this.showErr(err);
    });
  }

  getOrderRevenueSummary(children: Shipment[]) {
    const handleSettingNotMatch = this.order?.clientIds?.includes('01HRZEJV34B82ZVT2B1TE06VWX'); // gopuff
    const dataSummary = makeOrderRevenueSummary(this.order, this.order?.metadata?.shipments, children, { handleSettingNotMatch, includeAdditionalCost: false });
    Log.d('dataSummary:', dataSummary);
    this.isZeroRevenue = dataSummary.total == 0;
  }

  viewPodItem(groupIndex, podIndex) {
    let el = document.getElementById(`pod-img-${groupIndex}-${podIndex}`);
    this.scrollToElement(el, { behavior: 'smooth' });
  }

  public attachedFileUrl(podItem: AttachedFile): string {
    //tạm thời không dùng presigned cho PDF để tránh lỗi CORS khi preview
    if(podItem.uploadProgress == 'UPLOADING') return 'https://warp-public-media.s3.us-west-2.amazonaws.com/images/photo-uploading.png'
    return AttachedFileUtil.attachedFileUrl(podItem, !this.isPdf(podItem));
  }

  private preparePodUrlForTask(task) {
    if (!Utils.isArrayNotEmpty(task.podArr)) {
      return;
    }
    for (let i = 0; i < task.podArr.length; i++) {
      let podItem = task.podArr[i];
      if (this.isPdf(podItem)) {
        let url = this.attachedFileUrl(podItem);
        podItem.loadingTask = pdfjsLib.getDocument({ url, withCredentials: true });
        podItem.loadingTask.promise.then(function (pdf) {
          pdf.getPage(1).then(function (page) {
            var desiredWidth = 80;  // css class attached-pod
            var viewport = page.getViewport({ scale: 1 });
            var scale = desiredWidth / viewport.width;
            var scaledViewport = page.getViewport({ scale: scale });
            var canvas = <HTMLCanvasElement>document.getElementById(`pod-${task.id}-${podItem.id || podItem._id}`);
            if(!canvas) return;
            var context = canvas.getContext('2d');
            canvas.height = scaledViewport.height;
            canvas.width = scaledViewport.width;
            canvas.height = 98;
            canvas.width = 78;
            var renderContext = {
              canvasContext: context,
              viewport: scaledViewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then().catch(e => console.error('DPF render error ', e));
          });
        }, function (err) {
          console.error('PDF loading error ', err);
        });
      } else {
        podItem.imgUrl = this.attachedFileUrl(podItem);
      }
    }
  }

  formatDate(date) {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  isConfirmPOD(item) {
    if (item.podConfirmed?.when) return true;
    return false;
  }

  onBtnConfirm = (item) => {
    let params = { "id": item._id };
    let url = `${Const.APIURI_TASKS}/${item.taskId}/confirm-pod`;
    item.onProgress = true;
    this.api.POST(url, params).subscribe(
      (resp) => {
        item.podConfirmed = resp.data?.confirmed ? {
          ...resp.data?.confirmed,
          byUser: resp.data?.confirmed?.byUser ?? resp.data?.confirmed?.by,
          byId: resp.data?.confirmed?.byId ?? resp.data?.confirmed?.by?.id,
        } : resp.data?.podConfirmed;
        item.onProgress = false;
        this.checkCanGenerateInvoice();
        this.showSuccess("Your POD has been confirm successfully.");
      },
      (err) => {
        item.onProgress = false;
        this.showErr(err);
      }
    );
  };

  onBtnUnConfirm = async (item) => {
    let params = { "id": item._id };
    let url = `${Const.APIURI_TASKS}/${item.taskId}/un-confirm-pod`;
    item.onProgress = true;
    let [err] = await to(this.api.POST(url, params).toPromise());
    if (err) {
      this.showErr(err);
    } else {
      item.podConfirmed = undefined;
      this.checkCanGenerateInvoice();
    }
    item.onProgress = false;
  }

  canUnconfirmPOD(podItem): boolean {
    let isMine = () => {
      if (podItem.podConfirmed?.byUser?.id) {
        return podItem.podConfirmed?.byUser?.id == this.authUser.id;
      } else {
        return podItem.podConfirmed?.byUser?._id == this.authUser.warpId;
      }
    }
    return this.isConfirmPOD(podItem) && isMine();
  }

  public isHasIssue(podItem) {
    return podItem.hasIssue && !podItem.podConfirmed?.when;
  }

  public onNoteFetchingDone(podItem, data: NotesComponentEventFetchDataDone) {
    const before = podItem.hasIssue;
    const after = data.countTotal > 0;
    if (before != after) {
      // Âm thầm correct
      podItem.hasIssue = after;
      this.correctPodIssue(podItem);
    }
  }

  private correctPodIssue(podItem) {
    const url = Const.APIV2(`pods/${podItem._id}/correct_issue_status`);
    this.api.POST(url).subscribe();
  }

  public onNoteItemAdded(podItem, data: NotesComponentEventItemAdded) {
    if (!podItem.hasIssue) {
      podItem.hasIssue = true;
    }
  }

  public onNoteItemRemoved(podItem, data: NotesComponentEventItemRemoved) {
    if (data.countBeforeRemove <= 1 && podItem.hasIssue) {
      podItem.hasIssue = false;
    }
  }

  private correctPod(shipmentId) {
    const url = Const.APIV2(`shipments/${shipmentId}/correct_pod`);
    return this.api.POST(url).toPromise();
  }

  public onBtnCorrectPod() {
    const isMultiShipments = this.order?.shipmentIds?.length > 1;
    if (isMultiShipments) {
      const shipmentIds = this.order?.shipmentIds;
      const result = shipmentIds.map(shipmentId => {
        return this.correctPod(shipmentId);
      });

      Promise.all(result)
        .then(() => {
          this.showSuccess('Correct POD successfully.');
        })
        .catch(err => { this.showErr(err) });
    } else {
      this.correctPod(this.order?.shipmentIds[0])
        .then(() => {
          this.showSuccess('Correct POD successfully.');
        })
        .catch(err => { this.showErr(err) });
    }
  }

  gotoPodConfirmation(group, tab = 'need_confirm') {
    return `${Const.routeAdminPODNeedConfirm}?tab=${tab}&jobId=${group?.jobId}&search=${group?.warpId}`
  }

  public get btnTitleGenInvoice() {
    let title = "Generate Invoice";
    if (this.order?.invoiceFileId || this.order?.invoice?.id) title = "Re-Generate Invoice";
    return title
  }
}
