<ng-container *ngIf="dedicatedLaneData">
  <div class="dedicated-lane-info">
    <div class="title">Dedicated Lane Settings</div>
    <div style="padding-top: 8px;">
      <div class="sub-title">From zip code: <span>{{dedicatedLaneData?.origin?.zipcode}}</span></div>
      <div class="sub-title">To zip code: <span>{{dedicatedLaneData?.destination?.zipcode}}</span></div>
      <div class="sub-title">Pickup Date: <span>{{dedicatedLaneData?.pickupDate}}</span></div>
      <div *ngIf="dedicatedLaneData.rate" class="sub-title">Rate: <span>{{formatMoney(dedicatedLaneData.rate ?? 0)}}</span></div>
    </div>
  </div>
</ng-container>



<div class="title">Shipment info</div>

<ng-container *ngIf="isSingle">
  <shipment-info-single [modelDraft]="modelDraft"></shipment-info-single>
</ng-container>
<ng-container *ngIf="!isSingle">
  <shipment-info-batch [modelDraft]="modelDraft" (onSortedTaskIdsChanged)="updateSortedTaskIds($event)"></shipment-info-batch>
</ng-container>

