import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { EditShipmentEntryServiceOption } from "@app/admin/shipment-entry/components/edit-shipment/service-option";
import { Const } from "@const/Const";
import { Const as WarpConst } from "@wearewarp/universal-libs"
import { DialogService } from "@dialogs/dialog.service";
import { DateUtil } from "@services/date-utils";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { ContactType, TaskType } from '@wearewarp/types';
import { EditShipmentEntryTextField, EditShipmentEntryTextFieldKeys } from "@app/admin/shipment-entry/components/edit-shipment/access-code";
import { EditShipmentEntryReferenceNumber } from "@app/admin/shipment-entry/components/edit-shipment/reference-number";
import { EditShipmentEntryContactInfo } from "@app/admin/shipment-entry/components/edit-shipment/contact-info";
import { BookAppointment, ModalHelper, UpdateTimeWindows } from "@wearewarp/ng-antd";
import { UIHelper } from "@services/UIHelper";
import { RoleManager } from "@services/role-manager";
import { EditShipmentEntryLocation } from '../../../shipment-entry/components/edit-shipment/location-address';
import { FormDataEditLocationAddress } from '@wearewarp/types-server-admin/form-data/shipment-entry';
import { ScheduleAppointments } from '@app/admin/shipment-queues/v2/list-need-appointments/schedule-appointments';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DeliveryInfoService } from './delivery-info.service';
import { EditShipmentEntryInstructions } from "@app/admin/shipment-entry/components/edit-shipment/instructions";



@Component({
    selector: '[shipment-delivery-info]',
    templateUrl: './delivery_info.html',
    styleUrls: ['../styles.scss', './index.scss']
})
export class ShipmentDeliveryInfo extends BaseComponent {
    @Input() shipment: any = null
    @Input() info: any = null
    @Input() editMode: boolean = false
    @Input() isShowActualTime: boolean = false
    subscription: Subscription;
    name: string = null
    windows: string[] = []
    showExtra: boolean = this.editMode
    primaryContact: any = null
    secondaryContact: any = null
    serviceOptions: any[] = []
    scheduledAppointment: any = null
    actualTime: any = {}
    appointmentNotes: any = [];

    @Output() onUpdated: EventEmitter<any> = new EventEmitter<any>();

    constructor(private modalHelper: ModalHelper, private deliveryInfoService: DeliveryInfoService) {
        super();
    }

    ngOnInit() {
        this.subscription = this.deliveryInfoService.appointmentRequired$
        .pipe(filter(data => !!data))
        .subscribe(data => {
        if (this.info &&
            this.shipment.id === data.childId &&
            this.info.id === data.infoId) {
        this.info.requiresAppointment = data.requiresAppointment;
        }
    });
    }

    ngOnDestroy() {
        if (this.subscription) {
        this.subscription.unsubscribe();
        }
    }

    onReload() {
        this.onUpdated.emit()
    }

    isShowSettingTask(shipment, warehouseId) {
        const { warehouseTasks = [] } = shipment
        return warehouseTasks.filter(it => it.warehouseId === warehouseId).length
    }

    ngOnChanges(): void {
        const { type, contacts, serviceOptions, warehouseId } = this.info || {}
        this.name = type ? (type.substring(0, 1) + type.substring(1).toLowerCase()) : null
        this.windows = this.getTimePeriods(this.info)
        this.primaryContact = (contacts || [])?.filter(it => it && it?.type == Const.ContactType.primary)?.[0]
        this.secondaryContact = (contacts || [])?.filter(it => it && it?.type == Const.ContactType.secondary)?.[0]
        this.serviceOptions = (serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt)).filter(it => it)
        this.scheduledAppointment = this.getAppointmentTime()
        this.loadSortLabel()
        this.loadActualTime();
        this.getAppointmentNote()
    }

    onToggleExtra() {
        this.showExtra = !this.showExtra
    }

    getAppointmentTime() {
        const { appointmentInfo } = this.info || {}
        if (!appointmentInfo) return null
        return DateUtil.displayTimeWindow(appointmentInfo, {
            timezone: this.info?.addr?.metadata?.timeZoneStandard,
            formatDateOnly: 'MMM DD',
            format: "MMM DD h:mm A",
        })
    }

    getTimePeriods(info) {
        let windows = info?.windows
            ?.map((window) =>
                DateUtil.displayTimeWindow(window, {
                    timezone: info?.addr?.metadata?.timeZoneStandard,
                    formatDateOnly: 'MMM DD',
                    format: "MMM DD h:mm A",
                })
            );
        return windows?.filter(it => it)
    }

    getDisplayActualTime(time) {
        return DateUtil.displayLocalTime(time,
            {
                timezone: this.info?.addr?.metadata?.timeZoneStandard,
                format: 'MM/DD/YY h:mm A'
            }
        )
    }

    onBtnEditServiceOptions() {
        DialogService.openFormDialog1(EditShipmentEntryServiceOption, {
            nzComponentParams: {
                headerText: `${this.name} Service Options`,
                model: { serviceOptions: this.info.serviceOptions },
                type: this.info.type,
                closeOnSuccess: true,
                onSave: data => this.update(data),
                onRefreshDetailOrder: () => this.onUpdated?.emit()
            },
            nzClassName: "modal",
        });
    }

    private update(data) {
        const url = Const.APIV2(`shipments/${this.shipment.id}/delivery-info/${this.info.id}`)
        return this.api.PUT(url, data)
    }

    onBtnEditWindowsTime() {
        UpdateTimeWindows.openModal(this.modalHelper, {
            onSubmitError: err => UIHelper.showErr(err),
            onSubmitSucceeded: data => this.onUpdated?.emit(),
            nzTitle: `${this.name} Time Windows`,
            nzComponentParams: {
            timezone: this.info.addr?.metadata?.timeZoneStandard,
            model: {
                windows: this.info.windows,
                reasonCodeId: this.info.reasonCodeId,
            },
            reasonCodes: MasterData.getChangeDateTimeReasons(),
            submit: data => this.update(data),
        }
        });
    }

    onBtnEditBookAppointment() {
        DialogService.openFormDialog1(ScheduleAppointments, {
            nzTitle: `${this.name} Appointment`,
            nzWidth: '60%',
            nzClosable: true,
            nzClassName: 'schedule-appointment',
            nzComponentParams: {
                isDialog: true,
                data: {
                    stopId: this.info.id,
                    shipmentId: this.shipment.id,
                    addr: this.info?.addr,
                    contacts: this.info?.contacts || [],
                    warehouseContacts: this.info?.warehouseContacts || [],
                    windows: this.info?.windows || [],
                    appointmentInfo: this.info?.appointmentInfo,
                    reasonCodeId: this.info?.reasonCodeId,
                    timezone: this.info.addr?.metadata?.timeZoneStandard,
                    locationName: this.info.locationName,
                    type: this.info.type
                },
                closeOnSuccess: true,
                updateSuccess: (result) => {
                    if (result?.data?.appointmentInfo) {
                    this.info.appointmentInfo = result.data.appointmentInfo;
                    this.scheduledAppointment = this.getAppointmentTime();
                    this.onUpdated.emit();
                    }
                }
            }
        });
    }
    onRemoveAppointmentDate() {
        this.confirmDeletion({
            message: 'Are you sure you want to delete the appointment time?',
            fnOk: () => {
                this.update({appointmentInfo: null}).subscribe((res) => {
                    if (res.message === 'Success') {
                        this.showInfo(`The scheduled ${this.name} time has been deleted`)
                        this.onUpdated?.emit();
                    }
                })
            },
        })

    }

    onBtnEditContactInfo(contactType: ContactType) {
        const contacts = (this.info?.contacts || []).filter(it => it);
        let indexExist = -1;
        let contactInfo;
        for (let i = 0; i < contacts.length; i++) {
            if (contacts[i] && contacts[i].type === contactType) {
                contactInfo = contacts[i];
                indexExist = i;
                break;
            }
        }
        DialogService.openFormDialog1(EditShipmentEntryContactInfo, {
            nzComponentParams: {
                headerText: `${this.name} ${Utils.capitalizeFirstLetter(contactType)} Contact`,
                model: contactInfo,
                contactType: contactType,
                closeOnSuccess: true,
                onSave: data => {
                    if (indexExist === -1) {
                        contacts.push({ ...data, type: contactType })
                    } else {
                        contacts[indexExist] = { ...data, type: contactType };
                    }
                    return this.update({ contacts })
                },
                onRefreshDetailOrder: () => this.onUpdated?.emit()
            },
            nzClassName: "modal",
        });
    }

    onBtnEditReferenceNumber() {
        let refNums = this.info.refNums;
        DialogService.openFormDialog1(EditShipmentEntryReferenceNumber, {
            nzComponentParams: {
                headerText: `${this.name} Reference Numbers`,
                type: this.info.type,
                model: { refNums },
                closeOnSuccess: true,
                onSave: data => this.update(data),
                onRefreshDetailOrder: () => this.onUpdated?.emit()
            },
            nzClassName: "modal",
        });
    }

    private locationType(type: TaskType) {
        return type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
    }

    onBtnEditAddressInfo() {
        const deliveryInfo = this.info;
        const formDataModel: FormDataEditLocationAddress = {
            locationName: deliveryInfo.locationName,
            addr: deliveryInfo.addr,
            warehouseId: String(deliveryInfo.warehouseId),
        }
        DialogService.openFormDialog1(EditShipmentEntryLocation, {
            nzAutofocus: null,
            nzComponentParams: {
                headerText: `${this.locationType(this.info.type)} Location`,
                type: this.info.type,
                model: formDataModel,
                closeOnSuccess: true,
                onSave: data => this.update(data),
                onRefreshDetailOrder: () => this.onUpdated?.emit()
            },
            nzClassName: "modal",
        });
    }

    onBtnEditAccessCode() {
        this.editTextField(this.name, 'accessCode');
    }

    onBtnEditInstruction() {
        DialogService.openFormDialog1(EditShipmentEntryInstructions, {
            nzComponentParams: {
                headerText: `${this.name} Instructions (External)`,
                model: {
                    instructions: this.info?.instructions,
                    instructionImgs: this.info?.instructionImgs,
                },
                closeOnSuccess: true,
                onSave: data => {
                    const url = Const.APIV2(`shipments/${this.shipment.id}/update-instructions/${this.info.id}`)
                    return this.api.putFormData(url, data)
                    // if (indexExist === -1) {
                    //     contacts.push({ ...data, type: contactType })
                    // } else {
                    //     contacts[indexExist] = { ...data, type: contactType };
                    // }
                    // return this.update({ data })
                },
                onRefreshDetailOrder: () => this.onUpdated?.emit()
            },
            nzClassName: "modal",
        });
    }

    

    onBtnEditNote() {
        this.editTextField(this.name, 'note');
    }

    private editTextField(name, key: EditShipmentEntryTextFieldKeys) {
        DialogService.openFormDialog1(EditShipmentEntryTextField, {
            nzComponentParams: {
                keys: [key],
                model: { [key]: this.info[key] },
                isFieldMultiLine: (key: EditShipmentEntryTextFieldKeys) => {
                    switch (key) {
                        case 'accessCode':
                            return false;
                        case 'instructions':
                        case 'note':
                            return true;
                    }
                },
                getFieldLabel(key) {
                    switch (key) {
                        case 'accessCode': return `${name} Access Code`;
                        case 'instructions': return `${name} Instructions (External)`;
                        case 'note': return `${name} Note (Internal)`;
                    }
                },
                onSave: (data) => this.update(data),
                onRefreshDetailOrder: () => this.onUpdated?.emit()
            },
            nzClassName: "modal",
        });
    }

    copyValue(value) {
        Utils.copyTextToClipboard(value, (e) => {
            if (e) { } else {
                this.showSuccess(
                    `Copied to the clipboard: ${value}`
                );
            }
        });
    }

    appointmentRequireChanged(event) {

      // WPD-3206
      if (event === false) {
        const excludeClientIds = ["01J5ECNEZ7R8F3JK37X0DYR0T3", "01J77DTCM30C1H1VWDVVA1G2BC"] // BTTR
        const isExcludeClient = this.shipment?.clientId && excludeClientIds.includes(this.shipment.clientId);
        if (!isExcludeClient) {
          const { type = '', serviceOptions = [] } = this.info || {};
          const isRequirePickupAppt = type === WarpConst.TaskType.PICKUP && serviceOptions.some((it) => it === 'residential-pickup');
          const isRequireDropoffAppt = type === WarpConst.TaskType.DROPOFF && serviceOptions.some((it) => it === 'residential-delivery');
          if (isRequirePickupAppt || isRequireDropoffAppt) {
            setTimeout(() => this.info.requiresAppointment = true, 1000);
            return DialogService.showDialog('Residential Pickup/Delivery requires appointment!');
          }
        }
      }

      this.update({requiresAppointment: event || false}).subscribe((res) => {
          if (res.message === 'Success') {
              this.showInfo(`${this.name} Appointment requirement updated`)
              this.onUpdated?.emit();
          }
      })
    }

    sortLabel: any = null
    loadSortLabel() {
        this.sortLabel = null
        const { warehouseId } = this.info || {}
        const canViewSortLabel = warehouseId && this.authUser.roles.filter(it => it.code === RoleManager.warehouseOperator).length > 0
        if (!canViewSortLabel) return
        const url = Const.APIV2(`labels/search`)

        this.api.POST(url, {warehouseId, shipmentId: this.shipment.id}).subscribe((res) => {
            if (res && res.length)
                this.sortLabel = res
        })
    }

    loadActualTime() {
        if (!this.info?.type) return;
        let arrivedTime: any = '';
        let departedTime: any = '';
        switch (this.info.type) {
            case Const.TaskType.PICKUP:
                arrivedTime = this.getDisplayActualTime(this.getStatusTime('arrivedAtPickup'));
                departedTime = this.getDisplayActualTime(this.getStatusTime('pickupSuccessful'));
                break;
            case Const.TaskType.DROPOFF:
                arrivedTime =  this.getDisplayActualTime(this.getStatusTime('arrivedAtDropoff'));
                departedTime = this.getDisplayActualTime(this.getStatusTime('complete'));
                break;
            default:
                break;
        }
        this.actualTime = {
            "arrived": arrivedTime ?? departedTime,
            "departed": departedTime
        }
    }

    getStatusTime(status) {
        const log = this.shipment?.statusChangeLog?.[status];
        let changeWhen = log?.changeWhen ?? log?.when;
        if (!changeWhen) return '';
        return changeWhen;
    }

    getAppointmentNote() {
        const subjectId = this.info.id;
        if(!subjectId) return;
        const url = `${Const.APIURI_CONVERSATIONS}/?subjectId=${subjectId}&subjectType=${WarpConst.ConversationSubjectType.deliveryInfo}&type=${WarpConst.ConversationType.book_appointment}`
        this.api.GET(url).subscribe(
        (resp) => {
            this.appointmentNotes = resp?.data?.list_data || [];
        },
        (err) => {
            console.log(err);
        }
        )
    }

    getWhenByForNote(note) {
        let rs: String = '';
        if(this.getFullName(note?.update?.user)) {
        rs = `${this.getFullName(note?.update?.user)}`;
        }
        if(this.displayDateTime(note?.update?.when, 'MM/DD/YYYY h:mm A')){
        rs += ` ${this.displayDateTime(note?.update?.when, 'MM/DD/YYYY h:mm A')}`;
        }
        return rs;
    }

    previewFiles(files) {
        if(!files?.length) return;
        DialogService.preview(files, 0);
    }
    viewImageItem(imgUrl) {
        if (!imgUrl) return;
        DialogService.previewImgs([imgUrl], 0);
    }
}
