<nz-table #basicTable [nzData]="items" [nzBordered]="true" [nzSize]="'small'" [nzFrontPagination]="false" [nzShowPagination]="false">
    <thead>
        <tr>
            <th
                [nzChecked]="checked"
                [nzIndeterminate]="indeterminate"
                nzLabel="Select all"
                (nzCheckedChange)="onAllChecked($event)"
            ></th>
            <th>Name</th>
            <th>Quantity</th>
            <th>Type</th>
            <th>Barcode</th>
            <th>Dimension</th>
            <th>Weight</th>
            <th>Service</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of basicTable.data">
            <td
              [nzChecked]="setOfCheckedId.has(data.id)"
              (nzCheckedChange)="onItemChecked(data.id, $event)"
            ></td>
            <td>{{ data.name }}</td>
            <td>{{ data.qty }}</td>
            <td>{{ data.qtyUnit }}</td>
            <td>{{ getBarcodeTxt(data) }}</td>
            <td>{{ getDimensionTxt(data) }}</td>
            <td>{{ getWeightTxt(data) }}</td>
            <td>{{ getServicetTxt(data) }}</td>
        </tr>
    </tbody>
</nz-table>