import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ValidationErrors } from "@app/admin/base/validator";
import { DataRepoLinehaulLane } from "@app/data-repo/linehaul-lane";
import { Const } from "@const/Const";
import { MasterData } from "@services/master.data";
import { BaseForm, FormGroupDeclarationStrict } from '@wearewarp/ng-form';
import { ResponseAddress } from "@wearewarp/types/rest-api/common";
import { FormDataLinehaulLaneV2Create } from "./interface";

@Component({
  selector: '[linehaul-lane-v2-create]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
})
export class LinehaulLaneV2Create extends BaseForm<FormDataLinehaulLaneV2Create> {
  protected formGroupDeclaration: FormGroupDeclarationStrict<FormDataLinehaulLaneV2Create> = {
    name: { label: "Name", required: true },
    vehicleTypes: { label: 'Vehicles', notAcceptEmpty: true, placeHolder: 'Select vehicles', required: true },
    warehouseIds: { label: '', type: 'formArray', initialValue: [null, null], required: true, validators: this.validateWarehouseIds.bind(this), },
    clientIds: {label: 'Customer'},
    group: {label: 'Group'},
    note: {label: 'Note', multiline: true},
  }

  get apiUrlClientList() { return Const.APIURI_CLIENTS_FOR_FILTER }
  get keys() { return Object.keys(this.formGroupDeclaration) }
  listWarehouses = [];
  groups = [];

  constructor(private dataRepo: DataRepoLinehaulLane) {
    super();
  }

  ngOnInit(): void {
    this.fetchGroups();
    super.ngOnInit();
  }

  private async fetchGroups() {
    this.groups = await this.dataRepo.getGroups();
  }

  getFormData(): FormDataLinehaulLaneV2Create {
    return super.getFormData();
  }
  
  getVehicleType() {
    const vehicleTypes = this.getItemValue('vehicleTypes');
    if (vehicleTypes?.length) return vehicleTypes;
    return null;
  }

  onValueChange(key, value) {
    switch (key) {
      case 'vehicleTypes':
        this.setItemValue(key, value);
        break;
    }
  }

  addGroup(input: HTMLInputElement) {
    const value = input.value.trim();
    if (!value) return;
    this.groups.push(value);
    this.setItemValue('group', value);
  }

  canRemoveStop(index: number): boolean {
    return this.getFormArrayLength('warehouseIds') > 2;
  }

  onBtnAddMoreStop() {
    this.addItemToFormArray('warehouseIds', null);
  }

  onBtnRemoveStop(index: number) {
    if (!this.canRemoveStop(index)) return;
    this.removeItemInFormArray('warehouseIds', index);
  }

  getAddressText(warehouse: {pickAddr: ResponseAddress}) {
    return MasterData.getAddressText(warehouse.pickAddr);
  }

  private validateWarehouseIds(input: FormControl): ValidationErrors | null {
    const arr: string[] = input.value;
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      if (!item) return {error: {en: `Stop ${i+1} is empty`}};
    }
    return null;
  }

  canSelectWarehouse = (warehouse: {id: string}) => {
    if (!warehouse) return true;
    const current = this.getItemValue('warehouseIds');
    return Array.isArray(current) && !current.includes(warehouse.id);
  }

}