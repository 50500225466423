import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDrawer } from "@drawers/base-form";
import { environment } from "@env/environment";
import { FileUtil } from "@services/file-util";
import { Subscription } from "rxjs";

@Component({
  selector: '[upload-order-pdf]',
  templateUrl: './index.html',
  styleUrls: ['../../../app.scss', '../../../dialogs/dialogs.scss', './index.scss']
})
export class UploadOrderPdf extends BaseFormDrawer {
  // for uploading a list of orders via excel/csv
  protected formGroupDeclaration: FormGroupDeclaration = {
    file: {label: 'File for uploading', required: true, type: 'uploadFile'},
    clientId: {label: 'Customer', required: true, placeHolder: 'Please select customer'},
    subClientId: {label: 'Sub Account', required: false, placeHolder: 'Please select'},
    shipmentType: {label: 'Shipment Type', required: true},
  };

  @Input() emitUploadedFile: (file: File) => void = () => {}
  subClientSubscription: Subscription;
    
  isRequired(key): boolean {
    switch (key) {
      case 'pickupWindows': return false;
      case 'dropoffWindows': return true;
      default: return super.isRequired(key);
    }
  }
  public isSelectingFile = false;
  public listShipmentTypes = Const.ShipmentTypesArray;
  public listClients = [];
  listSubClients = [];
  // public client = null;
  selectedClient = null;
  selectedSubClient = null;
  public selectedFile: File;
  public get txtBtnOK() {return 'Upload'}
  public get iconBtnOK() {return 'upload'}
  
  public errMsg;
  public err;
  public get labelSelectFile(): string {
    let key = 'file';
    return this.hasAttachedFile(key) ? this.getFileDesc(key) : 'Select file (pdf)'
  }
  
  @ViewChild('inputFile') inputFile: ElementRef<HTMLInputElement>;
  
  constructor() {
    super();
  }
  
  ngOnInit() {
    super.ngOnInit();
    this.getAllAccountManager();
  }

  isLoadingAccountManager: boolean = false;
  allAccountManagerUsers: any[] = [];
  private getAllAccountManager() {
    this.isLoadingAccountManager = true;
    this.api.GET(`${Const.APIURI_USERS}?accountingManagerOnly=1`).subscribe(
      (resp) => {
        this.isLoadingAccountManager = false;
        this.allAccountManagerUsers = resp.data.list_data;
      },
      (err) => {
        this.isLoadingAccountManager = false;
      }
    );
  }
  
  protected getApiUrl(): string {
    return `${environment.backendUrl}/v2/orders/create-draft-from-pdf`
  }
  
  onDropdownSelectChange(key: string, event) {
    switch(key) {
      case 'clientId':
        this.onClientChange(event);
        break;
      case 'subClientId':
        this.onSubClientChange(event);
        break;
    }
    
    // this.client = this.listClients.filter(it => (it._id == itemId || it.id == itemId))[0] || {};
  }

  private onClientChange(clientId) {
    this.selectedClient = this.listClients.find(item => item.id === clientId);
    //clear old and get new sub-client data
    this.selectedSubClient = null;
    this.setItemValue('subClientId', null);
    this.listSubClients = null;
    if (this.selectedClient) {
      this.fetchSubClient(this.selectedClient.id);
    }
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    let json: any = super.getFormData_JSON(isCreateNew);
    if (json.subClientId) {
      json.parentClientId = json.clientId;
      json.clientId = json.subClientId;
    }
    return json;
  }
  
  protected showInfo(message: any): void {
    // do nothing
  }
  
  protected startProgress() {
    super.startProgress();
    this.errMsg = '';
    this.err = null
  }

  async onFileSelected(key, files: FileList) {
    this.isSelectingFile = true;
    this.handleFileBeforeSelect(files[0]).then((file) => {
      super.onFileSelected(key, [file]);
      this.selectedFile = file;
      this.isSelectingFile = false;
    }).catch(() => {
      this.isSelectingFile = false;
    });
  }

  async handleFileBeforeSelect(file: File): Promise<File> {
    if (file.type !== 'application/pdf') {
      return file;
    }
    return await FileUtil.extractFirstPageFromPdf(file);
  }

  onBtnSave() {
    this.emitUploadedFile(this.selectedFile);
    super.onBtnSave();
  }

  protected onCreateFailure(err) {
    this.showErrDialog(err);
    this.showErr(err);
  }

  private onSubClientChange(clientId) {
    // get selected sub-client data
    this.selectedSubClient = this.listSubClients.find(item => item.id === clientId);
  }

  private fetchSubClient(parentId: string,subClientId:string = null) {
    this.subClientSubscription?.unsubscribe();
    let url = Const.APIV2(`${Const.APIURI_CLIENTS}/${parentId}/sub-client`);
    this.subClientSubscription = this.api.GET(url).subscribe(
      resp => {
        this.listSubClients = resp.data.list_data;
        if (subClientId) {
          this.selectedSubClient = this.listSubClients.find(item => item.id === subClientId);
        }
      }
    );
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.subClientSubscription?.unsubscribe();
  }

  get needUpdate(): boolean {
    if (this.selectedClient?.isCreditLimitExceeded) return false;
    return super.needUpdate;
  }

  get accountingManagerNames(): string {
    return this.allAccountManagerUsers.map(it => `${it.firstName} ${it.lastName}`).join(', ');
  }

  get accountingManagerEmails(): string {
    return this.allAccountManagerUsers.map(it => it.email).join(', ');
  }
}