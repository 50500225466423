import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortItemInfo } from './';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ShortItemInfo],
  exports: [ShortItemInfo]
})
export class ModuleShortItemInfo {}