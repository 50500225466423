<div class="section flex">
  <div linehaul-lane-v2-list-filter class="flex1"></div>
  <button nz-button (click)="onBtnCreate()"><i nz-icon nzType="plus" nzTheme="outline"></i> Create</button>
</div>
<div>
  <div *ngIf="isLoading && isNoData" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <div *ngIf="!isLoading && isNoData" class="nodata">No data</div>
  <ng-container *ngIf="!isLoading && !isNoData">
    <div *ngFor="let group of listGroups" class="section">
      <div *ngFor="let lane of group.lanes" linehaul-lane-v2-list-item [lane]="lane" class="lane"></div>
    </div>
  </ng-container>
</div>