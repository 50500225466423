import { Component, EventEmitter, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { BizUtil } from "@services/biz";
import { PlanningService } from "@services/planning.service";
import { StringULID } from "@wearewarp/types";
import { Shipment } from "@wearewarp/types/data-model";
import { NzModalRef } from "ng-zorro-antd/modal";
import _ from "underscore";

@Component({
    selector: '[exporting-route-dialog]',
    templateUrl: './exporting-dialog.html',
    styleUrls: ['./exporting-dialog.scss']
})
export class ExportingRouteDialog extends BaseDialog {
    _route: any
    @Input() session: any
    @Input() solutionId: any

    displayInfo: any = {}
    exporting: boolean = false
    service: PlanningService
    @Input() onExported: EventEmitter<any>
    
    constructor(private modal: NzModalRef) {
        super()
        this.service = new PlanningService(this.api)
    }

    @Input() set route(v) {
        this._route = v

        const { stops, cost } = this._route || {}
        const { travelDistance, travelTime, serviceTime, idleTime } = cost
        const totalTime = travelTime + serviceTime + idleTime

        this.displayInfo = {
            shipmentIds: _.uniq((stops || []).map(it => it.shipment?.metadata?.shipment?.warpId,).filter(it => it)),
            mileage: ((travelDistance || 0) / 1609.34).toFixed(1),
            time: totalTime || 0
        }
    }

    onCancel() {
        this.modal?.destroy()
    }

    onOk() {
        this.exportRoute(this._route)
    }

    onDone() {
        this.onExported?.emit(this._route)
        this.modal?.destroy()
    }

    exportRoute(route) {
        // should be on a controller
        let stops = route?.stops
        if (!stops?.length) return
        this.exporting = true
        const shipmentMap = {}
        const shipments = stops.map(it => it.shipment?.metadata?.shipment)
        for (let s of shipments) {
            shipmentMap[s.id] = s
        }
        const shipmentIds = stops.map(it => it.shipmentId)
        if (stops[0].type !== 'PICKUP') {
            // fake pickup
            shipmentIds.reverse()
            const pickups = shipmentIds.map(it => shipmentMap[it])
                .map(it => {
                    const pickup = BizUtil.getPickInfo(it)
                    return {
                        type: 'PICKUP',
                        shipmentId: it.id,
                    }
                })
            stops = pickups.concat(route.stops)
        }
        stops = stops.map(it => Object.assign({}, {
            type: it.type,
            shipmentId: it.shipmentId
        }))
        for (let stop of stops) {
            const shipment = shipmentMap[stop.shipmentId]
            const info = stop.type === 'PICKUP' ? BizUtil.getPickInfo(shipment) : BizUtil.getDropInfo(shipment)
            stop.id = info.id
        }

        this.service.notifyExporting(this.session.id, this._route.solutionId, this._route.id, _.uniq(shipmentIds)).subscribe(canExport => {
            if (!canExport) {
                this.showErr(`Cannnot export route. Please refresh and try again!`)
                return
            }
            return this.api.POST(Const.APIV2(`planning/create-route`), {stops}).subscribe((res) => {
                this.exporting = false
                if (res.id) {
                    this.service.notifyExport(this.session.id, res.id, route.solutionId, route.id).subscribe((e) => {
                        this.onDone()
                    })
                }
            }, (err) => {
                this.exporting = false
                this.showErr(err?.message ?? `Error while creating route!!!`)
            })    
        }, (err) => {
            this.exporting = false
            this.showErr(err?.message ?? `Error while creating route!!!`)
        })
    }
}