<div class="box-container">
    <div class="flex outer-box">
        <div class="stop-list-container">
            <div *ngIf="session" class="session-info">
                <div class="flex">
                    <div class="flex1">
                        <h4 style="margin-bottom: 0px;">
                            <span nz-icon nzType="left" nzTheme="outline" class="clickable" (click)="goBack()"></span>
                            {{ session.name }}
                        </h4>        
                    </div>
                    <div>
                        <span nz-dropdown [nzDropdownMenu]="menu">
                            <span nz-icon nzType="more" nzTheme="outline"></span>
                        </span>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                                <ng-container *ngIf="bidSession">
                                    <li nz-menu-item (click)="onViewBidSession()">
                                        <span class="right5" nz-icon nzType="eye" nzTheme="outline"></span>
                                        View Bidding Session
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="!bidSession">
                                    <li nz-menu-item (click)="onCreateBidSession()">
                                        <span class="right5" nz-icon nzType="plus" nzTheme="outline"></span>
                                        Create Bidding Session
                                    </li>
                                </ng-container>
                                <li nz-menu-item (click)="loadSession()">
                                    <span class="right5" nz-icon nzType="sync" nzTheme="outline"></span>
                                    Refresh
                                </li>
                                <li nz-menu-item (click)="onTriggerRoutingBtn()">
                                    <span class="right5" nz-icon nzType="node-index" nzTheme="outline"></span>
                                    Start Routing
                                </li>
                                <li nz-menu-item (click)="onCopyShipmentIds()">
                                    <span class="right5" nz-icon nzType="copy" nzTheme="outline"></span>
                                    Copy Shipment IDs
                                </li>
                                <li *ngIf="_session?.triggeredRouting?.problemId" nz-menu-item (click)="onGoToRouting()">
                                    <span class="right5" nz-icon nzType="deployment-unit" nzTheme="outline"></span>
                                    Go to Routing Tool
                                </li>
                            </ul>
                          </nz-dropdown-menu>
                    </div>
                </div>
            </div>
            <nz-tabset nzSize="small">
                <nz-tab [nzTitle]="shipmentTitleTemplate">
                    <ng-template #shipmentTitleTemplate>
                        <img class="icon-detail" style="margin-left: 10px;" src="assets/img/pallet.svg"> [{{ displayInfo.shipmentCount }}]
                    </ng-template>
                    <div *ngIf="loadingShipment" class="loading">
                        <i nz-icon nzType="loading" nzTheme="outline"></i>
                    </div>
                    <div style="padding: 8px;">
                        <input nz-input
                            placeholder="Search Shipment"
                            [(ngModel)]="shipmentFilter"
                            (ngModelChange)="onShipmentFilterUpdate()"
                        >
                    </div>
                    <div *ngIf="planningShipments?.length">
                        <div *ngFor="let group of groups" class="shipment-group">
                            <div class="group-name flex">
                                <div class="flex1">{{ group.name }}</div>
                                <div *ngIf="false">
                                    <span nzSize="small" nz-dropdown [nzDropdownMenu]="groupmenu" nzType="text" class="sub-title clickable">...</span>
                                    <nz-dropdown-menu #groupmenu="nzDropdownMenu">
                                        <ul nz-menu>
                                          <li nz-menu-item>Un-Group</li>
                                          <li nz-menu-item>Rename</li>
                                        </ul>
                                    </nz-dropdown-menu>
                                </div>
                            </div>
                            <div *ngFor="let item of group.shipments" planning-shipment-detail [shipment]="item"
                                (onHover)="onMouseOverShipment($event)"
                                (onRemove)="onRemoveShipment($event)"
                                (onAddToGroup)="onAddShipmentToGroup($event)"
                                (onUnGroup)="onUnsetShipmentGroup($event)"
                            ></div>
                        </div>
                        <div *ngFor="let item of noGroup" planning-shipment-detail [shipment]="item"
                            (onHover)="onMouseOverShipment($event)"
                            (onRemove)="onRemoveShipment($event)"
                            (onAddToGroup)="onAddShipmentToGroup($event)"
                        ></div>
                    </div>
                    <div *ngIf="!planningShipments?.length">
                        No shipments added
                    </div>
                </nz-tab>
                <nz-tab [nzTitle]="vehicleTitleTemplate">
                    <ng-template #vehicleTitleTemplate>
                        <img class="icon-detail" src="assets/img/truck1.svg">[{{ session.fleet?.length || 0 }}]
                    </ng-template>

                    <div class="bottom15 left10">
                        <button nz-button nz-type="primary" (click)="onBtnAddVehicle()"><span nz-icon nzType="plus" nzTheme="outline"></span> Add Vehicle</button>
                    </div>
                    <div *ngIf="session.fleet">
                        <div *ngFor="let vehicle of session.fleet" class="vehicle-container">
                            <div class="flex">
                                <div class="flex1">{{ vehicle.name }}</div>
                                <div><span class="sub-title" nz-icon nzType="close" nzTheme="outline"></span>{{ vehicle.availability }}</div>
                            </div>
                            <div class="flex">
                                <div class="flex1 sub-title">
                                    <span class="right10"><img class="icon-detail" src="assets/img/pallet.svg"> {{ vehicle.capacity }} Pallets</span>
                                    <span *ngIf="vehicle.mileageLimit" class="right10">
                                        <span nz-icon nzType="node-index" nzTheme="outline"></span>{{ vehicle.mileageLimit }} mi
                                    </span>
                                    <span *ngIf="vehicle.maxTravelTime" class="right10">
                                        <span nz-icon nzType="clock-circle" nzTheme="outline"></span><time-duration [seconds]="vehicle.maxTravelTime"></time-duration>
                                    </span>
                                </div>
                                <div>
                                    <button nz-button nzSize="small" nzDanger (click)="onBtnRemoveVehicle(vehicle)">Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-tab>
                <nz-tab [nzTitle]="routeTitleTemplate">
                    <ng-template #routeTitleTemplate>
                        <span nz-icon nzType="deployment-unit" nzTheme="outline" style="margin-right: 6px;"></span>[{{ routes?.length || 0 }}]
                    </ng-template>
                    <div *ngIf="unrouted?.length" class="unrouted-group">
                        Unrouted Shipments:
                        <code style="display: inline-block;" class="clickable" (click)="onCopyText(s.metadata.shipment.warpId)" (mouseenter)="onMouseOverShipment(s)" (mouseleave)="onMouseOverShipment(null)" *ngFor="let s of unrouted">{{ s.metadata.shipment.warpId }}</code>
                    </div>
                    <div *ngIf="!routes?.length" style="padding: 8px;">
                        No Routes
                    </div>
                    <div *ngIf="routes?.length" style="padding: 0px 8px 8px 8px; border-bottom: solid 1px #eee;" class="flex">
                        <div class="flex1">{{ routes.length }} Routes</div>
                        <div>
                            <nz-switch [(ngModel)]="showRoutes" (ngModelChange)="onToggleShowRoutes()" nzSize="small"></nz-switch>
                        </div>
                    </div>

                    <div class="bottom15">
                        <div planning-route-detail *ngFor="let route of routes" [route]="route"
                            [session]="_session"
                            (onHoverStop)="onMouseOverStop($event)"
                            (onHoverRoute)="onMouseOverRoute($event)"
                            (onExport)="exportRoute($event)"
                            (onClick)="onClickRoute($event)"
                            (onShowRouteOnMap)="onToggleShowExportedRoute($event)"
                        ></div>
                    </div>

                </nz-tab>
                <nz-tab [nzTitle]="exportedRouteTitleTemplate">
                    <ng-template #exportedRouteTitleTemplate>
                        <span nz-icon nzType="node-index" nzTheme="outline" style="margin-right: 3px;"></span>[{{ (exportedRoutes?.length || 0) }} + <span class="status-complete">{{ (completedRoutes?.length || 0) }}</span>]
                    </ng-template>
                    <div *ngIf="!exportedRoutes?.length" style="padding: 8px;">
                        No Pending Routes
                    </div>

                    <div *ngFor="let group of routeGroups">
                        <div *ngIf="group.routes?.length" class="flex route-group-header">
                            <div class="flex1">
                                <span (click)="onToggleViewGroup(group)" class="clickable">
                                    <span class="right5" style="color: #888;">
                                        <span *ngIf="group.expanded" nz-icon nzType="up" nzTheme="outline"></span>
                                        <span *ngIf="!group.expanded" nz-icon nzType="down" nzTheme="outline"></span>
                                    </span>
                                    <span style="display: inline-block; min-width: 120px;" class="font-medium">{{ group.name }} [{{ group.routes.length }}]</span>
                                </span>
                            </div>
                            <div>
                                <nz-switch [(ngModel)]="group.display" (ngModelChange)="onToggleShowGroupRoutes(group)" nzSize="small"></nz-switch>
                            </div>
                        </div>
                        <ng-container *ngIf="group.expanded">
                        <div *ngFor="let route of group.routes" class="exported-route"
                            (mouseenter)="onMouseOverJob(route)"
                            (mouseleave)="onMouseOverJob(null)"
                            (click)="onClickJob(route)"
                        >
                            <div exported-job-detail [route]="route"
                                [map]="map"
                                (onToggleShowRoute)="onToggleShowCompletedRoute($event)">
                            </div>
                        </div>
                        </ng-container>
                    </div>

                </nz-tab>
            </nz-tabset>
        </div>
        <div class="flex1 map">
            <div class="map-container">
                <div #mapboxContainer></div>
                <canvas #deckCanvas></canvas>
                <span class="map-config" [map]="map" map-config></span>
            </div>            
        </div>
    </div>
</div>