import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { ShipmentNewLegForm } from './';
import { ModuleShortLocationInfo } from '../short_location/module';
import { ModuleTableShipmentItems } from '../table_shipment_items/module';

@NgModule({
  imports: [
    CommonModule,
    NzTableModule,
    NzIconModule,
    FormsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzAutocompleteModule,
    NzRadioModule,
    NzInputNumberModule,
    NzInputModule,
    NzGridModule,
    ModuleShortLocationInfo,
    ModuleTableShipmentItems
  ],
  declarations: [ShipmentNewLegForm],
  exports: [ShipmentNewLegForm]
})
export class ModuleShipmentNewLegForm {}