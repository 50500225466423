<div #filter filter-layout></div>

<!-- templates for filter fields -->
<ng-template #tplFilter_input let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <input nz-input [formControlName]="key" (keyup.enter)="onInputEnter(key, $event.target.value)"/>
  </ng-container>
</ng-template>

<ng-template #tplFilter_select let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select nzAllowClear nzShowSearch style="width: 100%;"
      [formControlName]="key"
      nzDropdownClassName="linehaul-lane-filter-select-group-{{key}}"
      [nzMode]="canSelectMultiple(key) ? 'multiple' : 'default'"
      [nzPlaceHolder]="'Select'"
      [nzLoading]="isLoading(key)"
      (ngModelChange)="onChange()">
      <nz-option *ngFor="let item of getListForSelection(key); let i = index;" [nzLabel]="getLabelForSelection(key, i)" [nzValue]="getValueForSelection(key, i)"></nz-option>
    </nz-select>
  </ng-container>
</ng-template>
