import {Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Const as WarpConst, WarpId } from "@wearewarp/universal-libs";
import ShipmentEntity from "@app/admin/dispatch/entity/ShipmentEntity";
import { MasterData } from "@services/master.data";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { DlgMergedOrderSuccess } from "./dlg/dlg-success";

@Component({
  selector: 'create-merged-order',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class ExternalOrderScreen extends BaseComponent {
  public isLoading: boolean = true;
  public isSubmitting: boolean = false;

  public shipments = [];

  constructor() {
    super()
  }

  @Input() onFinish: Function
  _shipment
  @Input() set shipment(input) {
    this._shipment = this.processShipment(input);
    this.getShipmentList();
  };
  get shipment() {
    return this._shipment;
  }
  setOfCheckedId = new Set<string>();

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  onCancel() {
    if (this.onFinish) this.onFinish();
  }

  getShipmentList() {
    this.isLoading = true;
    let url = `${Const.APIV2(Const.APIURI_SHIPMENTS)}/get-shipment-list-for-external-order?shipmentId=${this.shipment.id}`;
    this.api.GET(url).subscribe((res) => {
      this.isLoading = false;
      this.shipments = this.buildShipmentData(res.data?.list_data ?? []);
    }, (err) => {
      this.isLoading = false;
      this.showErr(err);
    });
  }

  onMergeShipmentToOrder(shipmentIds: string[]) {
    this.isLoading = true;
    shipmentIds.unshift(this.shipment.id)
    let url = `${Const.APIV2(Const.APIURI_ORDERS)}/create-external-order`;
    this.api.POST(url, {
      shipmentIds
    }).subscribe((res) => {
      this.isLoading = false;
      this.isSubmitting = false;
      if (this.onFinish) this.onFinish();
      const order = res.data;
      this.showDialogComplete([{ id: order.id, warpId: order.warpId, trackingCode: order.trackingCode, code: order?.code }])
      // this.showSuccess('Created order successfully!')
    }, (err) => {
      this.isLoading = false;
      this.isSubmitting = false;
      this.showErr(err);
    });
    
  }

  buildShipmentData(shipments) {
    const ret: any[] = [];
    for (const item of shipments) {
      ret.push(this.processShipment(item));
    }
    return ret;
  }
  processShipment(sh) {
    const client = sh?.metadata?.client;
      const deliveryInfos = sh?.deliveryInfos ?? [];
      const pickInfo = deliveryInfos.find(it => it.type == WarpConst.TaskType.PICKUP);
      const dropInfo = deliveryInfos.find(it => it.type == WarpConst.TaskType.DROPOFF);
      const orderId = sh?.orderId ?? sh?.metadata?.parent?.orderId;
      return {
        id: sh.id,
        warpId: sh.warpId,
        status: sh.status,
        code: sh.code,
        client,
        pickInfo,
        dropInfo,
        orderId,
      };
  }

  public canSubmit() {
    if (this.setOfCheckedId.size) {
      return true;
    }
    return false;
  }

  async onSubmit() {
    this.isSubmitting = true;
    const shipmentIds: string[] = Array.from(this.setOfCheckedId);
    if (shipmentIds?.length) {
      this.onMergeShipmentToOrder(shipmentIds);
    }
    
  }

  public showShipment(item) {
    return WarpId.showShipment(item)
  }

  public showShipmentCode(shipment) {
    return WarpId.showShipmentCode(shipment)
  }

  public getLocationAddress(info): string {
    return MasterData.getAddressText(info?.addr);
  }

  onRemoveShipment(shipment: ShipmentEntity) {
    this.shipments = this.shipments.filter(s => s.getId() != shipment.getId());
  }


  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
  }
  getDeliveryInfoTime(deliveryInfo) {
    return BizUtil.getDeliveryInfoTime(deliveryInfo, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D', 'appointmentFlag': '\n(Appointment Scheduled)' })
  }

  getReferenceNo(location){
    return (location?.refNums || [])?.join(', ') || 'N/A';
  }

  private showDialogComplete(orders) {
      this.modalService.create({
        nzContent: DlgMergedOrderSuccess,
        nzFooter: null,
        nzClosable: false,
        nzMaskClosable: false,
        nzKeyboard: false,
        nzComponentParams: { 
          orders
        }
      });
    }
}
