import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";

@Component({
	selector: '[shipment-warehouse-item]',
	templateUrl: './index.html',
	styleUrls: ['./style.scss']
})
export class ShipmentWarehouseItem extends BaseComponent {
	@Input() warehouseJobs: any[] = [];
}