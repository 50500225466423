
<div class="flex">
  <div class="flex1">
    <div>
      <a class="name" [routerLink]="routerLinkDetail(lane)" target="_blank">{{lane.name}}</a>
      <div class="flex top10">
        <span *ngFor="let info of lane.deliveryInfos; index as i">
            <span *ngIf="i > 0" nz-icon nzType="arrow-right" nzTheme="outline" class="left5"></span>
            {{ info.addr.city }}, {{ info.addr.state }} {{ info.addr.zipcode}}
        </span>
      </div>
      <div class="flex top10">
        <nz-tag *ngFor="let v of lane.vehicleTypes">{{ v.name }}</nz-tag>
      </div>
      <div *ngIf="lane.clients?.length" class="flex top10">
        <nz-tag *ngFor="let c of lane.clients" [nzColor]="'blue'">
          <a [routerLink]="routerLinkClient(c)" target="_blank">{{ c.name }}</a>
        </nz-tag>
      </div>
    </div>
    <div class="top10" *ngIf="data">
      Routes scheduled: {{data.scheduledForOutboundJobs}}
    </div>

    <div *ngIf="!data" class="loading-box">
      <div *ngIf="errMsg">
        <div class="danger">{{errMsg}}</div>
        <a (click)="onBtnRetry()">Retry</a>
      </div>
      <i *ngIf="isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
    </div>
  </div>
  <div class="flex1">
    <ng-container *ngIf="data">
      <div class="row">
        <div class="label">Ready for outbound</div>
        <div class="value">
          {{displayCount(data.readyForOutbound.pallets, 'pallet')}} 
          <span *ngIf="data.readyForOutbound.pallets > 0 && data.readyForOutbound.age > 0" [ngClass]="{'warning': data.readyForOutbound.age > 5}">(aging: {{displayCount(data.readyForOutbound.age, 'day')}})</span>
        </div>
      </div>
      <div class="row">
        <div class="label">At dock (has been routed)</div>
        <div class="value">
          {{displayCount(data.atDockHasRoute.pallets, 'pallet')}} 
          <span *ngIf="data.atDockHasRoute.pallets > 0 && data.atDockHasRoute.age > 0" [ngClass]="{'warning': data.atDockHasRoute.age > 5}">(aging: {{displayCount(data.atDockHasRoute.age, 'day')}})</span>
        </div>
      </div>
      <div class="row">
        <div class="label">At dock (not routed yet)</div>
        <div class="value">
          {{displayCount(data.atDockHasNoRoute.pallets, 'pallet')}} 
          <span *ngIf="data.atDockHasNoRoute.pallets > 0 && data.atDockHasNoRoute.age > 0" [ngClass]="{'warning': data.atDockHasNoRoute.age > 5}">(aging: {{displayCount(data.atDockHasNoRoute.age, 'day')}})</span>
        </div>
      </div>
      <div class="row">
        <div class="label">Incoming inbound</div>
        <div class="value">{{displayCount(data.incoming.pallets, 'pallet')}}</div>
      </div>
    </ng-container>
  </div>
</div>





