import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import DeliveryNetwork from "./network";
import { ulid } from 'ulid';
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { PlanningRouteDialog } from "@app/admin/planning/dialog";
import { UpdateShipmentReviewStatusForm } from "@app/admin/planning/update-review-status";
import { EventsContainer } from "@app/admin/components/events";
import { getFeatureFlags } from "@services/feature-flag.service";
import { InitReturnLeg } from "./init-return-leg/init-return-leg";
import { DataorchService } from "@services/dataorch.service";
import { Utils } from "@services/utils";
import { ExternalOrderScreen } from "@app/admin/shipment-entry/components/external-order";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { Shipment } from "@wearewarp/types/data-model";
import { DateUtil } from '@services/date-utils';
import { environment } from "@env/environment";

@Component({
    selector: '[shipment-structure]',
    templateUrl: './index.html',
    styleUrls: ['./styles.scss']
})
export class ShipmentStructure extends BaseComponent {
    @Input() items
    @Input() compact
    @Input() viewOnly
    @Input() presentedShipment = null;
    @Input() isShowActualTime: boolean = false;
    @Input() hideLateWarning: boolean = false;
    dataorch: DataorchService

    @Output() refreshDetailOrder: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('structure', { static: true }) svgRef: ElementRef;

    _shipment: any = null;
    @Input() set shipment(value) {
        this._shipment = value;
        this.loadNetwork()
        this.network?.redraw()

    }
    get shipment() {
        return this._shipment
    }

    _children: any = null;
    @Input() set children(value) {
        this._children = value
        this.network?.reset()
        this.loadNetwork()
        this.network?.redraw()
    }
    get children() {
        return this._children
    }

    @Input() highlights: any[] = []
    @Output() onSelectShipment: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSelectContextMenu: EventEmitter<any> = new EventEmitter<any>();
    @Output() onHover: EventEmitter<any> = new EventEmitter<any>();
    network: DeliveryNetwork = null
    showPopUp: boolean = false
    popUpLocation = [130, 40]
    popupData: any = null
    hoverData: any = null
    // presentedShipment: any = null
    warehouses = null
    newOutboundData = null
    editMode: boolean = true
    showEvents: boolean = false
    @Input() refId: string = 'struture'

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    ngOnInit(): void {
        this.onClick = this.onClick.bind(this)
        this.onMouseOver = this.onMouseOver.bind(this)
        this.onMouseOut = this.onMouseOut.bind(this)
        this.onSelectShipmentMenu = this.onSelectShipmentMenu.bind(this)
        this.onRouteCreated = this.onRouteCreated.bind(this)

        this.network = new DeliveryNetwork()
        this.loadNetwork()
        this.network.click = this.onClick
        this.network.mouseOut = this.onMouseOut
        this.network.mouseOver = this.onMouseOver
        this.network.highlight(this.highlights)
        // this.network.drawTo(this.refId)
        getFeatureFlags().isFlagSetNonBlocking('SHOW_EVENT').subscribe((res) => {
            if (res) {
                this.showEvents = true;
            }
        })
    }


    ngAfterViewInit() {
        this.network.drawTo(`#${this.refId}`)
        // child is set
    }

    ngOnChanges(): void {
        if (!this.network) {
            this.network = new DeliveryNetwork()
            this.network.click = this.onClick
            this.network.mouseOut = this.onMouseOut
            this.network.mouseOver = this.onMouseOver
            this.loadNetwork()
            // this.network.drawTo('#structure')
        } else {
            this.loadNetwork()
            this.network.highlight(this.highlights)
            this.network.redraw()
        }
        this.loadClientMetadata()
    }

    loadNetwork() {
        const showMain = (this.children ?? []).filter(it => it.shipmentTransitType == 'leg').length < 1
        if (!this.hideLateWarning) this.calculateLateStatus()
        this.network?.load(this.shipment, this.children, showMain)
    }

    onClick(event) {
        // toggle
        if (event.id === this.popupData?.id) {
            this.showPopUp = false
            this.popupData = null
            return
        }
        this.showPopUp = true
        this.popupData = event
        this.popupData.viewOnly = this.viewOnly
        const { coords } = event
        this.popUpLocation = [
            Math.max(0, coords[0][0] + coords[1][0] / 2 - 100),
            coords[0][1] + coords[1][1] + 10
        ]

        if (this.viewOnly) {
            const { shipment } = event
            if (shipment) {
                this.onSelectShipment?.emit(shipment._id)
            }
            return;
        }

        this.editMode = true;
        const { shipment } = event
        if (shipment) {
            this.showPopUp = false
            this.onSelectShipment?.emit(shipment._id)
            this.presentedShipment = shipment;
            if (this.presentedShipment.status === Const.OrderStatus.removed) {
                this.editMode = false;
            }
        }
    }

    onClosePopup() {
        this.showPopUp = false
        this.popupData = null
    }

    onCloseShipment() {
        this.closeShipment()
        this.popupData = null
        this.network?.redraw()
    }

    closeShipment() {
        this.presentedShipment = null
        this.confirmingRemoveLeg = false
        this.removingLeg = false
        this.network?.highlight([])
        this.onSelectShipment?.emit(null)
    }

    onMouseOver(event) {
        this.hoverData = event
        const { coords } = event
        const c = [
            Math.max(0, coords[0][0] + coords[1][0] / 2 - 100),
            coords[0][1] + coords[1][1] + 10
        ]
        this.hoverData['coords'] = c
        this.onHover?.emit(this.hoverData)
    }

    onMouseOut(event) {
        this.hoverData = null
        this.onHover?.emit(null)
    }

    onSelectLocationMenu(action) {
        const { location } = this.popupData
        if (!location) {
            this.onClosePopup()
            return
        }
        if (action === 'NEW_OUTBOUND') {
            this.onTriggerNewOutbound(location)
        }
        if (action === 'INITIALIZE_RETURN') {
            this.onTriggerInitializeReturn(location)
        }
        this.onClosePopup()
    }

    onTriggerNewOutbound(location) {
        this.closeShipment()
        this.newOutboundData = {
            from: location
        }
    }
    onTriggerInitializeReturn(location) {
        DialogService.openDialog(InitReturnLeg, {
            nzComponentParams: {
                from: location,
                parent: this.shipment,
                onSave: data => this.addReturnLeg(data),
                onSubmitSucceeded: resp => {
                    const created = resp.data.list_data[0];
                    this.onNewLegCreated(created)
                }
            },
            nzClassName: "modal-task-return",
            nzCentered: true,
            nzTitle: null,
            nzClosable: false,
        });
    }
    onCancelCreateNewLeg() {
        this.newOutboundData = null
    }
    onNewLegCreated(created) {
        if (created) {
            this.children.push(created)
            this.loadNetwork()
            this.network.highlight([created.id])
            this.network.redraw()

            this.onSelectShipment?.emit(created._id)
            this.presentedShipment = created
            this.editMode = true
            this.showInfo(`New LEG was created successfully: <b>${this.showShipmentCode(created)}</b>`)
        }
        this.newOutboundData = null
    }

    onSelectShipmentMenu(action) {
        const { shipment } = this.popupData
        if (!shipment) {
            this.onClosePopup()
            return
        }

        if (action === 'SHOW_DETAIL') {
            this.closeShipment()
            this.newOutboundData = null
        }
        if (action === 'DUPLICATE') {
            this.duplicateLeg(shipment)
        }
        this.onClosePopup()
    }

    public duplicatingLeg: boolean = false
    duplicateLeg(shipment) {
        // return alert(`Feature is being upgraded, it's back soon.`);
        const duplicated: any = {
            deliveryInfos: shipment.deliveryInfos.map(it => Object.assign({}, it)),
            parentId: shipment.parentId,
            id: ulid(),
            clientId: shipment.clientId,
            shipmentType: shipment.shipmentType,
            isCrossDock: shipment.isCrossDock,
            shipmentModeId: shipment.shipmentModeId,
            equipmentId: shipment.equipmentId,
            tempRange: shipment.tempRange,
            shipmentTransitType: shipment.shipmentTransitType,
            itemIds: shipment.itemIds,
            name: '...'
        }
        for (let info of duplicated.deliveryInfos) {
            info.windows = []
            info.id = ulid()
        }

        this.children.push(duplicated)
        this.loadNetwork()
        this.network.redraw()

        const url = Const.APIV2('shipments/add_sub_shipment')
        this.duplicatingLeg = true
        this.api
            .POST(url, {
                parentId: shipment.parentId,
                shipments: [duplicated]
            })
            .subscribe(
                (resp) => {
                    this.duplicatingLeg = false
                    const created = resp.data.list_data[0]
                    this.children = this.children.filter(it => it.id !== duplicated.id)
                    this.onNewLegCreated(created)
                },
                (err) => {
                    this.duplicatingLeg = false
                    this.showErr(`Error while duplicating LEG ${shipment.warpId}: ${JSON.stringify(err)}`)
                }
            )
    }

    addReturnLeg(data) {
        const url = Const.APIV2('shipments/add_return_leg')
        return this.api.POST(url, {
            parentId: this.shipment.id,
            shipment: data.shipment,
            returnReason: data.returnReason
        });
    }

    confirmRemovingLeg() {
        this.confirmingRemoveLeg = true
    }

    cancelRemovingLeg() {
        this.confirmingRemoveLeg = false
    }


    public confirmingRemoveLeg: boolean = false
    public removingLeg: boolean = false
    removeLeg(shipment) {
        const url = Const.APIV2(`shipments/${shipment.id}`)
        this.removingLeg = true
        this.api.DELETE(url).subscribe((res) => {
            this.removingLeg = false
            if (res.message === 'Success') {
                this.popupData = null;
                this.confirmingRemoveLeg = false;
                this.onDeliveryUpdated();
            } else {
                this.showErr(`Error while deleting shipment leg!`)
            }
        }, (err) => {
            this.removingLeg = false
            this.showErr(`Error while deleting shipment leg. ${err}`)
        })
    }

    canDelete(shipment) {
        if (!shipment.parentId) return false
        if (shipment.lastJobId) return false
        if (shipment.externalId) return false
        if (shipment.status === Const.OrderStatus.removed) return false;
        return true
    }

    canCreateSingleRoute(shipment) {
        if (shipment.lastJobId) return false;
        if (shipment.status === Const.OrderStatus.removed) return false;
        return true;
    }

    toggleEdit() {
        this.editMode = !this.editMode
    }

    public refreshingShipment: boolean = false
    onDeliveryUpdated() {
        if (!this.presentedShipment) return
        // refresh data
        const urls = [];
        if (this.presentedShipment.id) urls.push(Const.APIV2(`shipments/${this.presentedShipment.id}`));
        if (this.presentedShipment.parentId) {
            urls.push(Const.APIV2(`shipments/${this.presentedShipment.parentId}`));
            urls.push(Const.APIV2(`shipments/${this.presentedShipment.parentId}/all-childrens`));
        }
        if (!urls.length) return

        this.refreshingShipment = true
        this.api.concurrentGET(urls).subscribe((res) => {
            const resChild = res[0];
            const resParent = res[1];
            const resChildren = res[2];
            this.refreshingShipment = false
            if (resChild.message === 'Success') {
                this.presentedShipment = resChild.data
                this.children = resChildren.data?.list_data || [];
                this.presentedShipment = this.children.find(it => it.id === this.presentedShipment.id) || this.presentedShipment;
                this.shipment = resParent?.message === 'Success' ? resParent.data : resChild.data;
                this.network.reset()
                this.loadNetwork()
                this.network.redraw()
                this.refreshDetailOrder.emit()
            }
        }, (err) => {
            this.refreshingShipment = false
            // this.showErr(`Error while refreshing shipment ${this.presentedShipment.warpId}`)
            this.showErr(err);
        })
    }

    onRouteCreated(route) {
        this.presentedShipment.lastJobId = route.id
        if (this.presentedShipment.parentId) {
            let url = Const.APIV2(`shipments/${this.presentedShipment.parentId}/all-childrens`);
            this.refreshingShipment = true
            this.api.GET(url).subscribe((res) => {
                const resChildren = res;
                this.refreshingShipment = false
                if (resChildren.message === 'Success') {
                    this.children = resChildren.data?.list_data || [];
                    this.presentedShipment = this.children.find(it => it.id === this.presentedShipment.id) || this.presentedShipment;
                    this.network.reset()
                    this.loadNetwork()
                    this.network.redraw()
                }
            }, (err) => {
                this.refreshingShipment = false;
                this.showErr(err);
            })
        }
        this.refreshDetailOrder.emit()
        this.showInfo(`Route ${route.code} has been created`)
    }

    onBtnManualRouteShipment() {
        if (!this.presentedShipment) return
        if (this.presentedShipment.lastJobId) return
        // if ((data.shipmentIds || []).length === 1) {
        //   data = data?.metadata?.shipments[0];
        // }
        DialogService.openDialog(PlanningRouteDialog, {
            nzComponentParams: {
                shipments: [this.presentedShipment],
                onRouteCreated: this.onRouteCreated
            },
            nzWidth: 848,   // content 800px + padding left, right 24px
            nzClassName: 'modal-no-padding',
            nzCentered: true,
        })
    }

    clientMeatadata: any = {}
    loadClientMetadata() {
        if (this.clientMeatadata?.clientId === this.shipment?.clientId) return
        this.clientMeatadata = {
            clientId: this.shipment?.clientId
        }
        if (!this.shipment?.clientId) return
        const url = Const.APIV2(`${Const.APIURI_METADATA}/CUSTOMER_${this.shipment.clientId}/PLANNING`)
        this.api.GET(url).subscribe((res) => {
            this.clientMeatadata = res
            this.clientMeatadata.clientId = this.shipment.clientId
        })
    }

    updateShipmentReviewStatus(shipment) {
        this.modalService.create({
            nzTitle: `Update Review Status for shipment <b>${this.showShipmentCode(shipment)}</b>`,
            nzContent: UpdateShipmentReviewStatusForm,
            nzWidth: "800px",
            nzComponentParams: {
                id: shipment.id,
                warpId: shipment.warpId,
                status: shipment.review?.status || {}
            },
            nzOnOk: (comp) => {
                comp.onSave().subscribe((res) => {
                    const { review } = res.data
                    shipment.review = review
                }, (err) => {
                    this.showErr(err)
                })
            },
            nzOnCancel: (comp) => {
                comp.onCancel()
            }
        })
    }

    showShipmentHistory(presentedShipment) {
        this.drawerService.create({
            nzContent: EventsContainer,
            nzClosable: false,
            // nzWidth: "90%",
            nzBodyStyle: {
                // padding: "0"
            },
            nzContentParams: {
                type: 'SHIPMENT',
                id: presentedShipment.id
            }
        });
    }

    shouldShowMarkAsReturnShipment(shipment): boolean {
        const dropoffInfo = shipment?.deliveryInfos?.find(it => it.type === Const.TaskType.DROPOFF);
        if (!dropoffInfo) return false;

        const pickupInfoParent = this.shipment?.deliveryInfos?.find(it => it.type === Const.TaskType.PICKUP);
        if (!pickupInfoParent) return false;

        return Utils.isSameAddress(pickupInfoParent?.addr, dropoffInfo?.addr);
    }

    loadingMarkAsReturnShipment: boolean = false
    onMarkAsReturnShipment(shipment) {
        this.loadingMarkAsReturnShipment = true;
        const url = Const.APIV2(`shipments/${shipment.id}/mark-as-return-shipment`)
        this.api.POST(url).subscribe((res) => {
            this.loadingMarkAsReturnShipment = false;
            if (res.message === 'Success') {
                this.onDeliveryUpdated();
            } else {
                this.showErr(`Error while marking as return shipment!`)
            }
        }, (err) => {
            this.loadingMarkAsReturnShipment = false
            this.showErr(`Error while marking as return shipment: ${err}`)
        });
    }

    onOpenCreateExternalOrder(shipment) {
        this.openDrawer(shipment, {
            title: "Create merged order",
            component: ExternalOrderScreen
        })
    }

    externalOrderId(shipment: Shipment) {
        return shipment?.metadata?.externalOrders?.[0]?.orderId;
    }

    isCreateExternalOrder(shipment) {
        return shipment.shipmentType == Const.ShipmentTypes.lessThanTruckload &&
            (shipment.status == WarpConst.ShipmentStatus.needCarrier || !shipment.status) &&
            (!shipment.isCrossDock || (shipment.isCrossDock && !shipment?.legIds?.length))
            && !shipment?.subIds?.length
            && !shipment?.metadata?.externalOrders?.length
    }
    private openDrawer(shipment, { title, component }) {
        const ref = this.drawerService.create({
            nzContent: component,
            nzClosable: false,
            nzWidth: "90%",
            nzBodyStyle: {
                padding: "0"
            },
            nzContentParams: {
                shipment,
                onFinish: (dt) => {
                    ref.close();
                    //   this.shipment.metadata.externalOrders = [
                    //     {
                    //         shipmentId: dt.shipmentIds?.[0],
                    //         orderId: dt.id
                    //     }
                    //   ]
                    this.refreshDetailOrder.emit()
                }
            }
        });
    }

    checkLateStatusDeliveryInfo(deliveryInfo: any, warpId: any) {
        let appointmentTime = null;
        if (deliveryInfo?.requiresAppointment && deliveryInfo?.appointmentInfo?.from && deliveryInfo?.appointmentInfo?.to) {
            appointmentTime = new Date(deliveryInfo.appointmentInfo.to)
        } else if (deliveryInfo?.windows?.[0]?.to) {
            appointmentTime = new Date(deliveryInfo.windows[0].to)
        } else {
            return deliveryInfo;
        }
        const currentTime = new Date()
        const isLate = currentTime > appointmentTime;
        if (isLate) {
            let lateData: any = {};
            const daysLate = Math.floor((currentTime.getTime() - appointmentTime.getTime()) / (1000 * 60 * 60 * 24));
            const hoursLate = Math.floor((currentTime.getTime() - appointmentTime.getTime()) / (1000 * 60 * 60)) - (daysLate * 24);
            lateData.message = daysLate === 0
                ? `${this.showShipmentWarpId(warpId)} late ${hoursLate} hours`
                : `${this.showShipmentWarpId(warpId)} late ${daysLate === 1 ? '1 day'
                    : daysLate + ' days'}${hoursLate > 0 ? ` ${hoursLate === 1 ? '1 hour'
                        : hoursLate + ' hours'}`
                        : ''}`;
            lateData.appointmentTime = DateUtil.displayLocalTime(appointmentTime, {
                timezone: deliveryInfo?.addr?.metadata.timeZoneStandard,
                format: 'M/D/YY, h:mm a'
            })
            lateData.currentTime = DateUtil.displayLocalTime(currentTime, {
                timezone: deliveryInfo?.addr?.metadata.timeZoneStandard,
                format: 'M/D/YY, h:mm a'
            });
            lateData.shipment = this.showShipmentWarpId(warpId)
            deliveryInfo.lateData = [lateData]
        }
        return deliveryInfo
    }

    checkLateStatusShipment(shipment) {
        if (!shipment?.deliveryInfos?.length || (shipment?.status && shipment?.status !== Const.OrderStatus.needCarrier)) {
            return shipment;
        }
        for (let index in shipment.deliveryInfos) {
            let d = shipment.deliveryInfos[index];
            shipment.deliveryInfos[index] = this.checkLateStatusDeliveryInfo(d, shipment.warpId);
        }
        return shipment
    }

    calculateLateStatus() {
        let showMain = !this.shipment?.legIds?.length
        if (showMain) {
            this._shipment = this.checkLateStatusShipment(this.shipment);
        } else {
            let childNeedCheck = (this.children || []).filter(it => it?.shipmentTransitType == 'leg' && (!it?.status || it?.status === Const.OrderStatus.needCarrier));
            for (let item of childNeedCheck) {
                let index = this.children.findIndex(it => it?.id === item?.id);
                if (index >= 0) {
                    this._children[index] = this.checkLateStatusShipment(item)
                }
            }
            // let realChildNeedCheck: any = [];
            // let grouped: any = {};
            // for(let ship of childNeedCheck) {
            //     let delIfs = ship?.deliveryInfos;
            //     let pick = delIfs?.find(it => it?.type === Const.TaskType.PICKUP);
            //     let drop = delIfs?.find(it => it?.type === Const.TaskType.DROPOFF);
            //     let key = `pick_${pick?.addr?.street}${pick?.addr?.city}${pick?.addr?.state}${pick?.addr?.zipcode}-drop_${drop?.addr?.street}${drop?.addr?.city}${drop?.addr?.state}${drop?.addr?.zipcode}`;
            //     if(!grouped[key]) {
            //         grouped[key] = [];
            //     }
            //     grouped[key].push(ship)
            // }
            // for(let key of Object.keys(grouped)) {
            //     let arr = grouped[key];
            //     if(arr?.length <= 1) {
            //         realChildNeedCheck.push(arr[0])
            //     } else {
            //         let earliest = Infinity;
            //         let chosen = arr[0];
            //         for(let item of arr) {
            //             let deliveryInfo = item?.deliveryInfos?.find(it => it?.type === Const.TaskType.DROPOFF)
            //             let appointmentTime = Infinity;
            //             if (deliveryInfo?.requiresAppointment && deliveryInfo?.appointmentInfo?.from && deliveryInfo?.appointmentInfo?.to) {
            //                 appointmentTime = new Date(deliveryInfo.appointmentInfo.to).getTime()
            //             } else if(deliveryInfo?.windows?.[0]?.to){
            //                 appointmentTime = new Date(deliveryInfo.windows[0].to).getTime()
            //             }
            //             if(earliest > appointmentTime) {
            //                 earliest = appointmentTime;
            //                 chosen = item
            //             }
            //         }
            //         realChildNeedCheck.push(chosen)
            //     }
            // };
            // for(let item of realChildNeedCheck) {
            //     let index = this.children.findIndex(it => it?.id === item?.id);
            //     if(index >= 0) {
            //         this._children[index] = this.checkLateStatusShipment(item)
            //     }
            // }
        }

    }

    getBidId(presentedShipment: Shipment): string | undefined {
        let lastJob = presentedShipment?.jobs?.length ? presentedShipment.jobs[presentedShipment.jobs.length - 1] : undefined;
        return lastJob ? (lastJob as any).bidId : undefined;
    }

    copyRouteTrackingLink() {
        const code = this.presentedShipment?.jobs?.at(-1)?.code;
        const clientId = this.presentedShipment?.clientId;
        if (!code || !clientId) {
            return;
        }
        const url = `${environment.trackingWebUrl}/${code}/${clientId}`;
        Utils.copyTextToClipboard(url, e => {
            if (e) {
                this.showErr('Cannot copy text to clipboard');
            } else {
                this.showSuccess('Copy successful');
            }
        })
    }
}

