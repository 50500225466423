<h3>Create new shipment from PDF file</h3>
<form class="form-detail" [formGroup]="formInput" nz-form>
    <div class="bottom20">
        <div class="form-register-label">Customer<span class="label-mark-required"></span></div>
        <select-by-searching
          formControlName="clientId" 
          [(listData)]="listClients"
          placeholder="Select Customer" 
          [version]="2"
          (ngModelChange)="onDropdownSelectChange('clientId', $event)">
        >
        </select-by-searching>

        <ng-container *ngIf="selectedClient?.isCreditLimitExceeded && allAccountManagerUsers.length">
          <div style="color: red; margin-top: 12px; margin-bottom: 12px;">
            Credit is Exceeded, please contact {{ accountingManagerNames }} ({{ accountingManagerEmails }}) for un-lock credit before order creation
          </div>
        </ng-container>
    </div>

    <div class="bottom20">
      <!--    sub client-->
      <ng-container *ngIf="listSubClients&&listSubClients.length>0">
        <div class="flex">
          <div *ngFor="let key of ['subClientId']" class="flex1 mw392">
            <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
            <nz-form-item class="right16">
              <nz-form-control>
                <nz-select (ngModelChange)="onDropdownSelectChange(key,$event)"
                          [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" [nzShowSearch]="true" nzAllowClear>
                  <nz-option *ngFor="let subClient of listSubClients" [nzLabel]="subClient.name"
                            [nzValue]="subClient.id">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="bottom20">
        <input #inputFile type="file" hidden accept=".pdf" (change)="onFileSelected('file', inputFile.files)">
        <button nz-button class="btn1" style="width: 100%;" (click)="inputFile.click()" [nzLoading]="isSelectingFile">
          <i *ngIf="hasAttachedFile('file')" nz-icon nzType="file-excel" nzTheme="outline"></i>
          {{labelSelectFile}}
          <span *ngIf="!hasAttachedFile('file')" class="label-mark-required"></span>
        </button>
    </div>

    <div *ngIf="selectedClient" class="bottom20">
        <div class="form-register-label">Shipment Type<span class="label-mark-required"></span></div>
        <nz-select nzBackdrop="true" style="width: 100%" nzAllowClear formControlName="shipmentType">
            <nz-option *ngFor="let item of listShipmentTypes" [nzValue]="item" [nzLabel]="getShipmentTypeName(item)"></nz-option>
        </nz-select>
    </div>
</form>
  
  <ng-template #tplTimeWindows let-key="key" let-form="form">
    <ng-container [formGroup]="form">
      <ng-container [formArrayName]="key">
        <form *ngFor="let item of getArrayControls(key); let i = index" nz-form [formGroupName]="i" class="pick-up-time">
          <nz-form-item class="col-date">
            <nz-form-control>
              <nz-date-picker class="first" formControlName="date" nzFormat="yyyy-MM-dd" nzPlaceHolder="Date"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <nz-time-picker [nzMinuteStep]="15" class="middle" formControlName="fromTime" nzFormat="HH:mm" nzPlaceHolder="From time"></nz-time-picker>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <nz-time-picker [nzMinuteStep]="15" class="last" formControlName="toTime" nzFormat="HH:mm" nzPlaceHolder="To time"></nz-time-picker>
            </nz-form-control>
          </nz-form-item>
          <ng-container *ngIf="i==0">
            <i *ngIf="isRequired(key)" class="btn-remove" style="cursor: default;"></i>
            <i *ngIf="!isRequired(key)" nz-icon nzType="close" nzTheme="outline" class="btn-remove" (click)="removeItemInFormArray(key, i)"></i>
          </ng-container>
          <i *ngIf="i>0" nz-icon nzType="close" nzTheme="outline" class="btn-remove" (click)="removeItemInFormArray(key, i)"></i>
        </form>
        <div class="pick-up-time">
          <div class="col-date"><button nz-button (click)="addItemToFormArray(key)" class="btn-add" style="width: 100%;" nzType="dashed"><i nz-icon nzType="plus" nzTheme="outline"></i></button></div>
          <div></div>
          <div></div>
          <i class="btn-remove"></i>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
  
  <div *ngIf="errMsg" class="danger" style="white-space: pre-wrap; word-wrap: break-word; margin-top: 30px;">
    <div>{{errMsg}}</div>
    <div *ngIf="err?.errors">
        <div *ngFor="let e of err.errors">
            Row {{e.location[0]}}: {{e.desc}}
        </div>
    </div>
</div>

  <div form-footer [onProgress]="onProgress" 
    [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress" 
    [nzIconOK]="iconBtnOK" [labelOK]="txtBtnOK" (onOK)="onBtnSave()" 
    (onCancel)="onClose()"></div>

