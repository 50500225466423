<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <div *ngIf="'locationName' as key">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
      <nz-form-item>
        <nz-form-control>
          <input nz-input [formControlName]="key"
            [nzAutocomplete]="autoComplete"
            (ngModelChange)="onFilterTextChange($event)">
            <nz-autocomplete #autoComplete [nzDefaultActiveFirstOption]="false">
              <cdk-virtual-scroll-viewport itemSize="32" minBufferPx="256" maxBufferPx="256" style="height: 256px;">
                <nz-auto-option *cdkVirtualFor="let item of locationsFiltered; let i = index" [nzValue]="item.name"
                  (selectionChange)="onLocationSelected($event, item)">
                  {{item.name}}
                </nz-auto-option>
              </cdk-virtual-scroll-viewport>
            </nz-autocomplete>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div *ngIf="'addr' as key">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
      <nz-form-item>
        <nz-form-control>
          <form-address [formControlName]="key"></form-address>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div *ngIf="isShowSkipUpdateChildren && 'skipUpdateChildren' as key">
     
      <nz-form-item>
        <nz-form-control>
          <div nz-checkbox formControlName="skipUpdateChildren">
            Skip Updating Leg
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress || locationHelper.isLoadingLocationDetail"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>