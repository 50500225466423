import to from 'await-to-js';
import { Component } from "@angular/core";
import { BaseList } from "@app/admin/base/list";
import { DataRepoLinehaulLane } from "@app/data-repo/linehaul-lane";
import { UIHelper } from "@services/UIHelper";
import { RequestCreateLinehaulLane, ResponseLinehaulLaneListItem } from "@wearewarp/types-server-admin/linehaul-lanes";
import { ModalHelper } from '@wearewarp/ng-antd';
import { LinehaulLaneV2Create } from '../create';
import { FormDataLinehaulLaneV2Create } from '../create/interface';

interface Group {
  name: string,
  lanes: ResponseLinehaulLaneListItem[],
}

@Component({
  selector: '[linehaul-lane-v2-list]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
})
export class LinehaulLaneV2List extends BaseList<ResponseLinehaulLaneListItem> {
  listGroups: Group[] = [];
  get limit(): number {
    return -1;
  }

  get isNoData(): boolean {
    return this.listData.length == 0;
  }

  constructor(private dataRepo: DataRepoLinehaulLane, private modalHelper: ModalHelper) {
    super();
  }

  protected getApiUrl(): string {
    return '';
  }

  protected getData(): void {
    this._getData();
  }

  private async _getData() {
    this.isLoading = true;
    const input = this.prepareParamGetList();
    const [err, data] = await to(this.dataRepo.getList(input));
    if (err) {
      UIHelper.showErr(err);
    } else if (data) {
      this.setPaginationData(data);
      this.onGetDataSucceeded(data);
    }
    this.isLoading = false;
  }

  protected onGetDataSucceeded(resp: any): void {
    const dic: {[key: string]: ResponseLinehaulLaneListItem[]} = {}
    const list = this.listData.sort((a,b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
    for (let item of list) {
      const groupName = item.group || '_WARP';
      if (!dic[groupName]) {
        dic[groupName] = [];
      }
      dic[groupName].push(item);
    }
    this.listGroups = [];
    for (let key of Object.keys(dic)) {
      this.listGroups.push({name: key, lanes: dic[key]});
    }
    this.listGroups.sort((a, b) => a.name.localeCompare(b.name));
  }

  onBtnCreate() {
    this.modalHelper.openForm(LinehaulLaneV2Create, {
      nzTitle: 'Create new linehaul lane',
      nzClassName: 'linehaul-lane-v2-create',
      nzComponentParams: {
        submit: data => this.createLane(data)
      },
      onSubmitSucceeded: () => {
        UIHelper.showSuccess(`New lane has been created`);
        this.getData();
      },
      onSubmitError: err => UIHelper.showErr(err),
    });
  }

  private createLane(data: FormDataLinehaulLaneV2Create) {
    return this.dataRepo.create(this.formDataToRequestCreate(data));
  }

  private formDataToRequestCreate(data: FormDataLinehaulLaneV2Create): RequestCreateLinehaulLane {
    return data;
  }

}