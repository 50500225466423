import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from "ng-zorro-antd/button";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { SharedModule } from '@app/admin/shared/shared.module';
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzInputModule } from "ng-zorro-antd/input";
import { RouterModule } from "@angular/router";
import { EditShipmentEntryEquipment } from "./equipment";
import { DetailModule } from "@app/admin/base/detail.module";
import { EditShipmentEntryLocation } from "./location-address";
import { MarkRequiredModule } from "@app/admin/base/mark-required/module";
import { EditShipmentEntryServiceOption } from "./service-option";
import { EditShipmentEntryTextField } from "./access-code";
import { EditShipmentEntryReferenceNumber } from "./reference-number";
import { EditShipmentEntryContactInfo } from "./contact-info";
import { EditShipmentEntryRevenue } from "./revenue";
import { EditShipmentEntryItems } from "./items";
import { FormInputCostV3Module } from "../forms/input-cost-v3/module";
import { ShipmentEntrySelectEditMode } from "./add-remove-stops/select-edit-mode";
import { EditShipmentEntryAddRemoveStops } from "./add-remove-stops";
import { FormEditShipmentBatchMultiPick } from "./add-remove-stops/multi-pick";
import { FormEditShipmentBatchMultiDrop } from "./add-remove-stops/multi-drop";
import { FormEditShipmentBatchMultiPickDrop } from "./add-remove-stops/multi-pick-drop";
import { BatchLocationSideIndicatorModule } from "../forms/batch-locations/side-indicator/module";
import { EditShipmentEntryAddLocation } from "./add-remove-stops/add-location";
import { EditShipmentEntryAddShipment } from "./add-remove-stops/add-shipment";
import { FormShipmentLocationModule } from "../forms/shipment-location/module";
import { FormOrderItemModule } from "../forms/order-items/module";
import { ShipmentEntryShareModule } from "../../share.module";
import { EditShipmentEntryMap } from "./add-remove-stops/map";
import { UiCommonModule } from "@app/admin/components/common/module";
import { AdditionalInvoiceDlg } from "./additional-invoice";
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UploadItemsManifest } from "./items/upload-manifest";
import { EditShipmentEntryInstructions } from "./instructions";
import { FormInputUploadImagesModule } from "@app/admin/base/form-input-upload-images/module";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NzFormModule,
        NzButtonModule,
        ReactiveFormsModule,
        NzSelectModule,
        NzRadioModule,
        NzModalModule,
        SharedModule,
        DetailModule,
        SelectBySearchingModule,
        NzCheckboxModule,
        NzInputModule,
        MarkRequiredModule,
        FormInputCostV3Module,
        ShipmentEntryShareModule,
        BatchLocationSideIndicatorModule,
        FormShipmentLocationModule,
        FormOrderItemModule,
        RouterModule,
        UiCommonModule,
        ScrollingModule,
        FormInputUploadImagesModule,
    ],
    declarations: [
      EditShipmentEntryEquipment,
      EditShipmentEntryLocation,
      EditShipmentEntryServiceOption,
      EditShipmentEntryTextField,
      EditShipmentEntryReferenceNumber,
      EditShipmentEntryContactInfo,
      EditShipmentEntryRevenue,
      EditShipmentEntryItems,
      ShipmentEntrySelectEditMode,
      EditShipmentEntryAddRemoveStops,
      FormEditShipmentBatchMultiPick,
      FormEditShipmentBatchMultiDrop,
      FormEditShipmentBatchMultiPickDrop,
      EditShipmentEntryAddLocation,
      EditShipmentEntryAddShipment,
      EditShipmentEntryMap,
      AdditionalInvoiceDlg,
      UploadItemsManifest,
      EditShipmentEntryInstructions,
    ],
    exports: [
      EditShipmentEntryEquipment,
      EditShipmentEntryLocation,
      EditShipmentEntryServiceOption,
      EditShipmentEntryTextField,
      EditShipmentEntryReferenceNumber,
      EditShipmentEntryContactInfo,
      EditShipmentEntryRevenue,
      EditShipmentEntryItems,
      ShipmentEntrySelectEditMode,
      EditShipmentEntryAddRemoveStops,
      EditShipmentEntryInstructions,
    ]
})
export class EditShipmentEntryModule {}