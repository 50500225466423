import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { LinehaulLaneV2List } from '.';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ModuleLinehaulLaneV2ListFilter } from '../list-filter/module';
import { ModuleLinehaulLaneV2Create } from '../create/module';
import { ModuleLinehaulLaneV2ListItem } from '../list-item/module';

@NgModule({
  imports: [
    CommonModule,
    NzButtonModule,
    NzIconModule,
    ModuleLinehaulLaneV2ListItem,
    ModuleLinehaulLaneV2ListFilter,
    ModuleLinehaulLaneV2Create,
  ],
  declarations: [
    LinehaulLaneV2List,
  ],
  exports: [
    LinehaulLaneV2List
  ],
  providers: [
  ]
})
export class ModuleLinehaulLaneV2List { }