import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { DataorchService } from "@services/dataorch.service";
import { PlanningService } from "@services/planning.service";
import _ from "underscore";

@Component({
    selector: '[linehaul-lane-list]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class LinehaulLaneList extends BaseComponent {
    service: PlanningService

    _lanes: any[] = []
    _groups: any[] = []
    loading: boolean = false

    constructor() {
        super()
        this.service = new PlanningService(this.api)

        this.loadLanes()
    }

    set lanes(lanes) {
        this._lanes = lanes

        const grouped = _.groupBy(lanes.filter((lane) => lane.group), (lane) => {
            return lane.group
        })
        const general = lanes.filter((lane) => !lane.group)

        const groups = []
        if (general.length) {
            groups.push({
                name: '_WARP',
                lanes: general
            })
        }
        for (let key in grouped) {
            groups.push({
                name: key,
                lanes: grouped[key]
            })
        }
        this._groups = groups.sort((a, b) => {
            return a.name.localeCompare(b.name)
        })
    }

    loadLanes() {
        this.loading = true
        this.service.listLinehaulLanes().subscribe((res) => {
            this.loading = false
            this.lanes = _.sortBy(res, 'name')
        }, (err) => {

        })
    }

    onRefreshBtn() {
        this.loadLanes()
    }

    selectLane(lane) {
        this.router.navigate([ Const.routePlanningLinehauls, lane.id])
    }
}